import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Typography, Button, theme, Grid, Dropdown, MenuProps, Drawer, Spin } from 'antd';
import { 
  DashboardOutlined, 
  LineChartOutlined, 
  FundOutlined, 
  GlobalOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  ApiOutlined,
  CrownOutlined,
  DollarOutlined,
  UsergroupAddOutlined,
  FileTextOutlined,
  SettingOutlined,
  LogoutOutlined,
  RobotOutlined
} from '@ant-design/icons';
// import MarketDashboard from './components/MarketDashboard';
import BotDashboard from './components/BotDashboard';
import StrategyBuilder from './components/StrategyBuilder';
import StrategyList from './components/StrategyList';
import Login from './components/Login';
import Register from './components/Register';
import LandingPage from './components/LandingPage';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { useNotifications } from './hooks/useNotifications';
import { Bot } from './types/api.types';
import './App.css';
import BotDetail from './components/bot/BotDetail';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { useBreakpoint } = Grid;

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const screens = useBreakpoint();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { logout, username } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useNotifications(username);

  const isMobile = !screens.lg;
  const isStrategyBuilder = location.pathname.startsWith('/strategy/build/');
  const isBotDetail = location.pathname.startsWith('/bot/');

  const pathToKey: { [key: string]: string } = {
    '/': 'dashboard',
    '/bot': 'bot',
    '/bot/': 'bot',
    '/analysis': 'analysis',
    '/analysis/': 'analysis',
    '/market': 'market',
    '/strategy': 'strategy',
    '/strategy/build': 'strategy'
  };

  const getCurrentMenuKey = () => {
    if (isStrategyBuilder) return 'strategy';
    if (isBotDetail) return 'bot';
    if (location.pathname.startsWith('/analysis/')) return 'analysis';
    
    const path = Object.keys(pathToKey)
      .sort((a, b) => b.length - a.length) // Sort by length descending to match most specific path first
      .find(p => location.pathname.startsWith(p));
    return path ? pathToKey[path] : 'dashboard';
  };

  const keyToPath: { [key: string]: string } = {
    'dashboard': '/',
    'bot': '/bot',
    'analysis': '/analysis',
    'market': '/market',
    'strategy': '/strategy'
  };

  const mainMenuItems = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: 'Tổng quan',
      active: false
    },
    {
      key: 'analysis',
      icon: <FundOutlined />,
      label: 'Phân tích',
      active: false
    },
    {
      key: 'bot',
      icon: <LineChartOutlined />,
      label: 'Bot',
      active: true
    },
    {
      key: 'strategy',
      icon: <RobotOutlined />,
      label: 'Chiến lược',
      active: true
    },
    {
      key: 'market',
      icon: <GlobalOutlined />,
      label: 'Chợ bot',
      active: false
    },
  ];

  const userMenuItems: MenuProps['items'] = [
    {
      key: 'account',
      icon: <UserOutlined />,
      label: 'Thông tin cơ bản'
    },
    {
      key: 'integration',
      icon: <ApiOutlined />,
      label: 'Tích hợp'
    },
    {
      key: 'membership',
      icon: <CrownOutlined />,
      label: 'Hội viên'
    },
    {
      key: 'credits',
      icon: <DollarOutlined />,
      label: 'Điểm credit'
    },
    {
      key: 'invite',
      icon: <UsergroupAddOutlined />,
      label: 'Mời bạn bè'
    },
    {
      key: 'notes',
      icon: <FileTextOutlined />,
      label: 'Ghi chú'
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Cài đặt'
    },
    {
      type: 'divider'
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: 'Đăng xuất',
      danger: true,
      onClick: async () => {
        await logout();
        navigate('/login');
      }
    }
  ];

  const handleMenuClick = ({ key }: { key: string }) => {
    const path = keyToPath[key];
    if (path) {
      navigate(path);
      isMobile && setMobileOpen(false);
    }
  };

  const SidebarContent = () => (
    <>
      <div style={{ 
        height: '64px', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        color: 'white',
        padding: '0 16px'
      }}>
        <Title level={4} style={{ 
          color: 'white', 
          margin: 0, 
          fontSize: collapsed && !isMobile ? '14px' : '18px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }}>
          {collapsed && !isMobile ? 'MT' : 'Mew Trader'}
        </Title>
      </div>
      <Menu 
        theme="dark" 
        mode="inline" 
        selectedKeys={[getCurrentMenuKey()]}
        items={mainMenuItems.filter(item => item.active)}
        onClick={handleMenuClick}
      />
      {screens.lg && (
        <div style={{ 
          position: 'absolute', 
          bottom: 0, 
          width: '100%', 
          padding: '16px', 
          display: 'flex', 
          justifyContent: 'center' 
        }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            className="sidebar-menu-button"
            style={{
              fontSize: '16px',
              width: 48,
              height: 48,
            }}
          />
        </div>
      )}
    </>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {isMobile ? (
        <Drawer
          placement="left"
          open={mobileOpen}
          onClose={() => setMobileOpen(false)}
          styles={{
            header: { display: 'none' },
            body: { padding: 0, background: '#001529' },
          }}
          width={200}
        >
          <SidebarContent />
        </Drawer>
      ) : (
        <Sider 
          trigger={null} 
          collapsible 
          collapsed={collapsed}
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 2,
          }}
        >
          <SidebarContent />
        </Sider>
      )}

      <Layout style={{ 
        marginLeft: isMobile ? 0 : (collapsed ? 80 : 200),
        transition: 'margin-left 0.2s',
      }}>
        {!isStrategyBuilder && !isBotDetail && (
          <Header style={{ 
            padding: '0 16px', 
            background: colorBgContainer,
            position: 'sticky',
            top: 0,
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: '0 1px 2px rgba(0,0,0,0.03)',
          }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {!screens.lg && (
                <Button
                  type="text"
                  icon={isMobile ? <MenuUnfoldOutlined /> : (collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />)}
                  onClick={() => isMobile ? setMobileOpen(true) : setCollapsed(!collapsed)}
                  className="sidebar-menu-button"
                  style={{
                    fontSize: '16px',
                    width: 48,
                    height: 48,
                  }}
                />
              )}
              <Title level={4} style={{ 
                margin: 0, 
                fontSize: '18px',
                display: isMobile ? 'block' : 'none',
                marginLeft: '8px',
              }}>
                Mew Trader
              </Title>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Dropdown 
                menu={{ items: userMenuItems }} 
                trigger={['click']}
                placement="bottomRight"
                arrow
              >
                <Button
                  type="text"
                  icon={<UserOutlined />}
                  style={{ 
                    fontSize: '16px',
                    width: 40,
                    height: 40,
                    borderRadius: '50%'
                  }}
                />
              </Dropdown>
            </div>
          </Header>
        )}
        <Content style={{ 
          ...(isStrategyBuilder || isBotDetail ? {
            padding: 0,
            margin: 0,
          } : {
            margin: isMobile ? '16px 12px' : '24px 16px',
            padding: isMobile ? 16 : 24,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }),
          minHeight: 280,
          overflow: 'initial'
        }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAuthenticated, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return (
      <div style={{ 
        height: '100vh', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        background: '#f0f2f5'
      }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const AppRoutes: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  const handleCreateStrategy = () => {
    navigate('/strategy');
  };

  const handleBotSetup = (bot: Bot) => {
    navigate('/strategy');
  };

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <MainLayout>
              <Routes>
                <Route path="/" element={<BotDashboard onCreateStrategy={handleCreateStrategy} onBotSetup={handleBotSetup} />} />
                <Route path="/bot" element={<BotDashboard onCreateStrategy={handleCreateStrategy} onBotSetup={handleBotSetup} />} />
                <Route path="/bot/:id" element={<BotDetail />} />
                <Route path="/analysis" element={<div>Phân tích</div>} />
                <Route path="/market" element={<div>Thị trường</div>} />
                <Route path="/strategy" element={<StrategyList />} />
                <Route path="/strategy/build" element={<Navigate to="/strategy" replace />} />
                <Route path="/strategy/build/:id" element={<StrategyBuilder />} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </MainLayout>
          </RequireAuth>
        }
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
