export const generateMockKlineData = () => {
  const data = [];
  // Đặt múi giờ Việt Nam
  const startDate = new Date('2024-01-01T00:00:00+07:00');
  let timestamp = startDate.getTime();
  let basePrice = 42000; // Giá BTC gần đây
  let trend = 0; // -1: downtrend, 0: sideways, 1: uptrend
  let trendCount = 0;
  let trendLength = Math.floor(Math.random() * 20) + 10;
  
  // Định nghĩa các vị trí sẽ có signal
  const signalPositions = [
    { index: 20, type: 'LONG' },
    { index: 50, type: 'SHORT' },
    { index: 90, type: 'LONG' },
    { index: 130, type: 'SHORT' },
    { index: 170, type: 'LONG' },
    { index: 35, type: 'SHORT' },
    { index: 75, type: 'LONG' },
    { index: 110, type: 'SHORT' },
    { index: 150, type: 'LONG' },
    { index: 185, type: 'SHORT' }
  ];
  
  for (let i = 0; i < 200; i++) {
    if (trendCount >= trendLength) {
      trend = Math.floor(Math.random() * 3) - 1;
      trendLength = Math.floor(Math.random() * 20) + 10;
      trendCount = 0;
    }

    let volatility = basePrice * 0.002; // 0.2% biến động cơ bản
    if (trend === 1) {
      volatility = basePrice * 0.003;
    } else if (trend === -1) {
      volatility = basePrice * 0.0025;
    }

    const change = (Math.random() - 0.4) * volatility;
    const open = basePrice;
    let close = open + change + (trend * volatility * 0.5);

    const highAdd = Math.random() * volatility * 0.5;
    const lowAdd = Math.random() * volatility * 0.5;
    const high = Math.max(open, close) + highAdd;
    const low = Math.min(open, close) - lowAdd;

    const volume = Math.floor(Math.random() * 100 + 50) * 0.1; // 5-15 BTC volume

    // Thêm signal nếu index nằm trong signalPositions
    const signalInfo = signalPositions.find(s => s.index === i);
    const signal = signalInfo ? signalInfo.type : null;

    data.push({
      timestamp: timestamp,
      open: Number(open.toFixed(2)),
      high: Number(high.toFixed(2)),
      low: Number(low.toFixed(2)),
      close: Number(close.toFixed(2)),
      volume: Number(volume.toFixed(2)),
      signal: signal
    });

    basePrice = close;
    timestamp += 15 * 60 * 1000; // 15 phút một nến
    trendCount++;
  }

  return data;
};

// Mock data cho bot trading
export const mockTradeHistory = [
  {
    id: 1,
    type: 'BUY',
    symbol: 'BTC/USDT',
    entryPrice: 42000,
    exitPrice: 43500,
    quantity: 0.1,
    profit: 150,
    profitPercentage: 3.57,
    timestamp: '2024-01-15T10:30:00+07:00',
    status: 'CLOSED'
  },
  {
    id: 2,
    type: 'SELL',
    symbol: 'ETH/USDT',
    entryPrice: 2300,
    exitPrice: 2250,
    quantity: 1,
    profit: -50,
    profitPercentage: -2.17,
    timestamp: '2024-01-15T11:45:00+07:00',
    status: 'CLOSED'
  },
  {
    id: 3,
    type: 'BUY',
    symbol: 'BNB/USDT',
    entryPrice: 320,
    exitPrice: 335,
    quantity: 2,
    profit: 30,
    profitPercentage: 4.69,
    timestamp: '2024-01-15T13:20:00+07:00',
    status: 'CLOSED'
  }
];

// Mock data cho giao dịch đang mở và lệnh chờ
export const mockOpenTrades = [
  {
    id: 1,
    type: 'BUY',
    symbol: 'BTC/USDT',
    entryPrice: 42500,
    currentPrice: 43200,
    quantity: 0.1,
    unrealizedProfit: 70,
    profitPercentage: 1.65,
    timestamp: '2024-01-16T08:30:00+07:00',
    status: 'FILLED',
    orderStatus: 'ACTIVE'
  },
  {
    id: 2,
    type: 'SELL',
    symbol: 'ETH/USDT',
    entryPrice: 2400,
    currentPrice: 2380,
    quantity: 1,
    unrealizedProfit: 20,
    profitPercentage: 0.83,
    timestamp: '2024-01-16T09:15:00+07:00',
    status: 'FILLED',
    orderStatus: 'ACTIVE'
  },
  {
    id: 3,
    type: 'BUY',
    symbol: 'BNB/USDT',
    entryPrice: 310,
    currentPrice: 310,
    quantity: 2,
    unrealizedProfit: 0,
    profitPercentage: 0,
    timestamp: '2024-01-16T10:00:00+07:00',
    status: 'PENDING',
    orderStatus: 'PENDING'
  },
  {
    id: 4,
    type: 'SELL',
    symbol: 'SOL/USDT',
    entryPrice: 95,
    currentPrice: 95,
    quantity: 5,
    unrealizedProfit: 0,
    profitPercentage: 0,
    timestamp: '2024-01-16T10:15:00+07:00',
    status: 'PENDING',
    orderStatus: 'PENDING'
  }
];

export const mockBotPerformance = {
  totalTrades: 150,
  winningTrades: 95,
  losingTrades: 55,
  winRate: 63.33,
  totalProfit: 2850,
  averageProfit: 19,
  maxDrawdown: -450,
  profitFactor: 2.1,
  sharpeRatio: 1.8,
  monthlyReturns: [
    { month: 'Jan', return: 5.2 },
    { month: 'Feb', return: 3.8 },
    { month: 'Mar', return: -2.1 },
    { month: 'Apr', return: 4.5 },
  ]
};

// Thêm dữ liệu mẫu cho biểu đồ profit theo ngày
export const mockDailyProfits = [
  { date: '2024-01-01', profit: 120 },
  { date: '2024-01-02', profit: -50 },
  { date: '2024-01-03', profit: 80 },
  { date: '2024-01-04', profit: 200 },
  { date: '2024-01-05', profit: -30 },
  { date: '2024-01-06', profit: 150 },
  { date: '2024-01-07', profit: 90 },
  { date: '2024-01-08', profit: -80 },
  { date: '2024-01-09', profit: 110 },
  { date: '2024-01-10', profit: 170 },
].map(item => ({
  ...item,
  timestamp: new Date(item.date + 'T00:00:00+07:00').getTime()
}));

// Thêm dữ liệu mẫu cho phân phối lãi lỗ
export const mockProfitDistribution = [
  { range: '-100 to -80', count: 5 },
  { range: '-80 to -60', count: 8 },
  { range: '-60 to -40', count: 12 },
  { range: '-40 to -20', count: 15 },
  { range: '-20 to 0', count: 20 },
  { range: '0 to 20', count: 25 },
  { range: '20 to 40', count: 18 },
  { range: '40 to 60', count: 12 },
  { range: '60 to 80', count: 8 },
  { range: '80 to 100', count: 5 },
];

// Tạo dữ liệu cho biểu đồ bot
const klineData = generateMockKlineData();

export const mockBotChartData = {
  symbol: 'BTC/USDT',
  timeframe: '15m',
  data: klineData,
  trades: [
    {
      time: '2024-01-15T10:30:00+07:00',
      price: 42000,
      type: 'BUY',
      quantity: 0.1
    },
    {
      time: '2024-01-15T11:45:00+07:00',
      price: 43500,
      type: 'SELL',
      quantity: 0.1
    },
    {
      time: '2024-01-16T08:30:00+07:00',
      price: 42500,
      type: 'BUY',
      quantity: 0.1,
      active: true
    }
  ]
};
