import { useEffect, useCallback, useRef } from 'react';
import { useAppDispatch } from './useAppDispatch';
import MQTTService from '../services/mqtt.service';
import { message } from 'antd';
import { setIsOnline } from '../store/slices/chatSlice';

export interface Notification {
  type: 'success' | 'error' | 'warning' | 'info';
  content: string;
  title?: string;
  duration?: number;
}

export const useNotifications = (username: string | null) => {
  const dispatch = useAppDispatch();
  const mqttService = useRef<MQTTService | null>(null);
  const notificationCallback = useRef<((data: any) => void) | null>(null);
  const retryCount = useRef(0);
  const maxRetries = 3;
  const cleanupRef = useRef<(() => void) | null>(null);

  const handleNotification = useCallback((data: Notification) => {
    // Hiển thị thông báo sử dụng Ant Design message
    const duration = data.duration || 4.5; // Default duration là 4.5s

    switch (data.type) {
      case 'success':
        message.success({
          content: data.content,
          duration,
        });
        break;
      case 'error':
        message.error({
          content: data.content,
          duration,
        });
        break;
      case 'warning':
        message.warning({
          content: data.content,
          duration,
        });
        break;
      default:
        message.info({
          content: data.content,
          duration,
        });
    }
  }, []);

  // Khởi tạo MQTT service và thiết lập kết nối
  const initializeMQTT = useCallback(async () => {
    try {
      if (!mqttService.current) {
        console.log('Initializing MQTT service for notifications...');
        mqttService.current = MQTTService.getInstance();
      }

      // Đăng ký callback để nhận thông báo khi trạng thái kết nối thay đổi
      const cleanup = mqttService.current.onConnectionChange((isConnected) => {
        console.log('MQTT notification connection status changed:', isConnected);
        dispatch(setIsOnline(isConnected));
        
        // Nếu kết nối thành công và có username, subscribe notifications
        if (isConnected && username && !notificationCallback.current) {
          console.log('Connection successful, subscribing to notifications...');
          notificationCallback.current = handleNotification;
          mqttService.current?.subscribeToNotifications(username, handleNotification);
          retryCount.current = 0; // Reset retry count on successful connection
        }
      });

      cleanupRef.current = cleanup;
    } catch (error) {
      console.error('Error initializing MQTT for notifications:', error);
      
      // Retry logic
      if (retryCount.current < maxRetries) {
        console.log(`Retrying MQTT initialization (${retryCount.current + 1}/${maxRetries})...`);
        retryCount.current++;
        
        // Exponential backoff: 2s, 4s, 8s
        const delay = Math.pow(2, retryCount.current) * 1000;
        setTimeout(() => {
          initializeMQTT();
        }, delay);
      } else {
        console.error('Max retries reached for MQTT initialization');
        message.error('Không thể kết nối đến hệ thống thông báo sau nhiều lần thử');
      }
    }
  }, [dispatch, username, handleNotification]);

  // Khởi tạo MQTT service khi component mount hoặc username thay đổi
  useEffect(() => {
    // Chỉ khởi tạo nếu có username
    if (!username) {
      // Cleanup nếu username trở thành null
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }
      if (notificationCallback.current && mqttService.current) {
        mqttService.current.unsubscribeFromNotifications(username || '', notificationCallback.current);
        notificationCallback.current = null;
      }
      mqttService.current = null;
      return;
    }

    initializeMQTT();

    return () => {
      // Gọi cleanup function nếu có
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }

      // Cleanup khi component unmount hoặc username thay đổi
      if (notificationCallback.current && username && mqttService.current) {
        console.log('Unsubscribing from notifications...');
        mqttService.current.unsubscribeFromNotifications(username, notificationCallback.current);
        notificationCallback.current = null;
      }
      mqttService.current = null;
    };
  }, [username, initializeMQTT]);

  // Return any methods if needed
  return {
    // Có thể thêm các phương thức để tương tác với notifications
  };
};
