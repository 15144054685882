import React from 'react';
import { Modal, Button, Form, DatePicker, Space, Switch, Input, Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

const { Text } = Typography;
const { RangePicker } = DatePicker;

interface BacktestModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  isAdvancedMode: boolean;
  setIsAdvancedMode: (value: boolean) => void;
  selectedTimeRange: string;
  setSelectedTimeRange: (value: string) => void;
  form: any;
}

const timeRangeOptions = [
  { label: '1 tháng', value: '1m', icon: <CalendarOutlined /> },
  { label: '3 tháng', value: '3m', icon: <CalendarOutlined /> },
  { label: '6 tháng', value: '6m', icon: <CalendarOutlined /> },
  { label: '1 năm', value: '1y', icon: <CalendarOutlined /> },
  { label: 'Tùy chỉnh', value: 'custom', icon: <CalendarOutlined /> },
];

const BacktestModal: React.FC<BacktestModalProps> = ({
  isVisible,
  onClose,
  onSubmit,
  isAdvancedMode,
  setIsAdvancedMode,
  selectedTimeRange,
  setSelectedTimeRange,
  form
}) => {
  const handleTimeRangeChange = (value: string) => {
    setSelectedTimeRange(value);
    
    if (value !== 'custom') {
      const now = dayjs();
      let startDate;
      
      switch (value) {
        case '1m':
          startDate = now.subtract(1, 'month');
          break;
        case '3m':
          startDate = now.subtract(3, 'months');
          break;
        case '6m':
          startDate = now.subtract(6, 'months');
          break;
        case '1y':
          startDate = now.subtract(1, 'year');
          break;
        default:
          startDate = now.subtract(3, 'months');
      }
      
      form.setFieldsValue({
        dateRange: [startDate, now]
      });
    }
  };

  const disabledDate = (date: Dayjs) => {
    return date.isAfter(dayjs());
  };

  return (
    <Modal
      title="Cài đặt Backtest"
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Hủy
        </Button>,
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Bắt đầu Backtest
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSubmit}
      >
        <Form.Item
          label="Khoảng thời gian"
          style={{ marginBottom: 24 }}
        >
          <div style={{ 
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '8px',
            marginBottom: '16px'
          }}>
            {timeRangeOptions.map(option => (
              <Button
                key={option.value}
                type={selectedTimeRange === option.value ? 'primary' : 'default'}
                onClick={() => handleTimeRangeChange(option.value)}
                icon={option.icon}
                style={{
                  width: '100%',
                  height: '40px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  fontSize: '14px',
                  transition: 'all 0.3s',
                  background: selectedTimeRange === option.value ? '#1677ff' : option.value === 'custom' ? '#f5f5f5' : '#fff',
                  borderColor: selectedTimeRange === option.value ? '#1677ff' : '#d9d9d9',
                  color: selectedTimeRange === option.value ? '#fff' : option.value === 'custom' ? '#1677ff' : 'rgba(0, 0, 0, 0.88)',
                }}
              >
                {option.label}
              </Button>
            ))}
          </div>
          
          {selectedTimeRange === 'custom' && (
            <Form.Item
              name="dateRange"
              rules={[{ required: true, message: 'Vui lòng chọn khoảng thời gian' }]}
              style={{ marginBottom: 0 }}
            >
              <RangePicker 
                showTime
                style={{ width: '100%' }}
                format="YYYY-MM-DD HH:mm:ss"
                disabledDate={disabledDate}
                placeholder={['Từ ngày', 'Đến ngày']}
              />
            </Form.Item>
          )}
        </Form.Item>

        <div style={{ marginBottom: 16 }}>
          <Space align="center">
            <Switch 
              checked={isAdvancedMode}
              onChange={setIsAdvancedMode}
            />
            <Text>Cài đặt nâng cao</Text>
          </Space>
        </div>

        {isAdvancedMode && (
          <>
            <Form.Item
              name="leverage"
              label="Đòn bẩy"
              initialValue={10}
            >
              <Input type="number" min={1} max={125} />
            </Form.Item>
            <Form.Item
              name="takeProfit"
              label="Take Profit (%)"
              initialValue={2.0}
            >
              <Input type="number" step="0.1" min={0.1} />
            </Form.Item>
            <Form.Item
              name="stopLoss"
              label="Stop Loss (%)"
              initialValue={1.0}
            >
              <Input type="number" step="0.1" min={0.1} />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default BacktestModal;
