import React from 'react';
import { Row, Col } from 'antd';
import EntryConditions from './EntryConditions';
import ExitConditions from './ExitConditions';
import { UIStrategy } from './types';

interface StrategyConditionsProps {
  strategy: UIStrategy;
}

const StrategyConditions: React.FC<StrategyConditionsProps> = ({ strategy }) => {
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} md={12}>
        <EntryConditions 
          buyConditions={strategy.entryConditions.buy}
          sellConditions={strategy.entryConditions.sell}
        />
      </Col>

      <Col xs={24} md={12}>
        <ExitConditions 
          takeProfit={strategy.exitConditions.takeProfit}
          stopLoss={strategy.exitConditions.stopLoss}
          indicators={strategy.indicators}
        />
      </Col>
    </Row>
  );
};

export default StrategyConditions;
