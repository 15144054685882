import ApiService from './api.service';
import { ApiResponse, Bot, Strategy, CreateBotRequest, CreateStrategyRequest } from '../types/api.types';

interface BotStatistics {
    totalProfit: number;
    activeBots: number;
    totalTrades: number;
    averageWinRate: number;
}

interface BotDetailStatistics {
    profit: number;
    trades: number;
    winRate: number;
    performance: {
        daily: number;
        weekly: number;
        monthly: number;
    }
}

interface Trade {
    id: string;
    type: 'buy' | 'sell';
    price: number;
    quantity: number;
    timestamp: string;
    profit: number;
}

interface TradeHistory {
    trades: Trade[];
    total: number;
}

interface BacktestRequest {
    strategy: number;
    symbol: string;
    start_date: string;
    end_date: string;
    parameters: {
        timeframe: string;
    }
}

interface BacktestResponse {
    id: number;
    strategy: number;
    strategy_name: string;
    symbol: string;
    start_date: string;
    end_date: string;
    parameters: any;
    status: 'pending' | 'running' | 'completed' | 'error' ;
    progress: number;
    result: any;
    error_message: string | null;
    created_at: string;
    updated_at: string;
}

interface UpdateStrategyRequest {
    name?: string;
    conditions?: {
        [key: string]: any;
    };
    timeframe?: string;
}

class TradingService extends ApiService {
    private static BASE_PATH = '/api/trading';

    // Bot Management
    static async getBots(): Promise<ApiResponse<Bot[]>> {
        return this.get<Bot[]>(`${this.BASE_PATH}/bots/`);
    }

    static async createBot(data: CreateBotRequest): Promise<ApiResponse<Bot>> {
        return this.post<Bot>(`${this.BASE_PATH}/bots/`, data);
    }

    static async updateBotStatus(botId: string, status: 'running' | 'stopped'): Promise<ApiResponse<Bot>> {
        return this.put<Bot>(`${this.BASE_PATH}/bots/${botId}/status/`, { status });
    }

    static async updateBotMode(botId: string, mode: 'live' | 'dry'): Promise<ApiResponse<Bot>> {
        return this.put<Bot>(`${this.BASE_PATH}/bots/${botId}/mode/`, { mode });
    }

    static async deleteBot(botId: string): Promise<ApiResponse<void>> {
        return this.delete<void>(`${this.BASE_PATH}/bots/${botId}/`);
    }

    // Bot Statistics
    static async getBotStatistics(): Promise<ApiResponse<BotStatistics>> {
        return this.get<BotStatistics>(`${this.BASE_PATH}/bots/statistics/`);
    }

    static async getBotDetailStatistics(botId: string): Promise<ApiResponse<BotDetailStatistics>> {
        return this.get<BotDetailStatistics>(`${this.BASE_PATH}/bots/${botId}/bot_statistics/`);
    }

    // Strategy Management
    static async getStrategies(): Promise<ApiResponse<Strategy[]>> {
        return this.get<Strategy[]>(`${this.BASE_PATH}/strategies/`);
    }

    static async createStrategy(data: CreateStrategyRequest): Promise<ApiResponse<Strategy>> {
        return this.post<Strategy>(`${this.BASE_PATH}/strategies/`, data);
    }

    static async getStrategyById(strategyId: string): Promise<ApiResponse<Strategy>> {
        return this.get<Strategy>(`${this.BASE_PATH}/strategies/${strategyId}/`);
    }

    static async updateStrategy(strategyId: string, data: UpdateStrategyRequest): Promise<ApiResponse<Strategy>> {
        return this.put<Strategy>(`${this.BASE_PATH}/strategies/${strategyId}/`, data);
    }

    static async shareStrategy(strategyId: string, email: string): Promise<ApiResponse<void>> {
        return this.post<void>(`${this.BASE_PATH}/strategies/${strategyId}/share/`, { email });
    }

    // Trade History
    static async getBotTrades(
        botId: string, 
        page: number = 1, 
        limit: number = 10,
        startDate?: string,
        endDate?: string
    ): Promise<ApiResponse<TradeHistory>> {
        const params = new URLSearchParams({
            page: page.toString(),
            limit: limit.toString(),
            ...(startDate && { startDate }),
            ...(endDate && { endDate })
        });

        return this.get<TradeHistory>(`${this.BASE_PATH}/bots/${botId}/trades/?${params}`);
    }

    // Backtest
    static async createBacktest(data: BacktestRequest): Promise<ApiResponse<BacktestResponse>> {
        return this.post<BacktestResponse>(`${this.BASE_PATH}/backtest/`, data);
    }

    static async getBacktestStatus(id: number): Promise<ApiResponse<BacktestResponse>> {
        return this.get<BacktestResponse>(`${this.BASE_PATH}/backtest/${id}`);
    }
}

export default TradingService;
