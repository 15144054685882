import React from 'react';
import { Card, List, Typography, Empty } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

interface EntryConditionsProps {
  buyConditions: string[];
  sellConditions: string[];
}

const EntryConditions: React.FC<EntryConditionsProps> = ({ buyConditions, sellConditions }) => {
  const hasConditions = buyConditions.length > 0 || sellConditions.length > 0;

  return (
    <Card
      size="small"
      className="strategy-card"
      title={
        <Title level={5} style={{ margin: 0, fontSize: '11px' }}>
          Điều kiện vào lệnh
        </Title>
      }
      style={{ borderRadius: '4px' }}
      bodyStyle={{ 
        padding: '8px',
        minHeight: hasConditions ? 'auto' : '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: hasConditions ? 'flex-start' : 'center'
      }}
    >
      {hasConditions ? (
        <div style={{ padding: '0 4px' }}>
          {buyConditions.length > 0 && (
            <>
              <Title level={5} style={{ color: '#52c41a', display: 'flex', alignItems: 'center', gap: '2px', fontSize: '11px', margin: '0 0 4px 0' }}>
                <ArrowUpOutlined /> Điều kiện mua
              </Title>
              <List
                size="small"
                dataSource={buyConditions}
                style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
                renderItem={item => (
                  <List.Item style={{ padding: '2px 4px', border: 'none', justifyContent: 'flex-start' }}>
                    <CheckCircleOutlined style={{ color: '#52c41a', marginRight: '4px', fontSize: '11px', flexShrink: 0 }} />
                    <Text style={{ fontSize: '11px' }}>{item}</Text>
                  </List.Item>
                )}
              />
            </>
          )}

          {buyConditions.length > 0 && sellConditions.length > 0 && (
            <div style={{ margin: '8px 0', borderTop: '1px solid #f0f0f0' }} />
          )}

          {sellConditions.length > 0 && (
            <>
              <Title level={5} style={{ color: '#ff4d4f', display: 'flex', alignItems: 'center', gap: '2px', fontSize: '11px', margin: '0 0 4px 0' }}>
                <ArrowDownOutlined /> Điều kiện bán
              </Title>
              <List
                size="small"
                dataSource={sellConditions}
                style={{ display: 'flex', flexDirection: 'column', gap: '2px' }}
                renderItem={item => (
                  <List.Item style={{ padding: '2px 4px', border: 'none', justifyContent: 'flex-start' }}>
                    <CloseCircleOutlined style={{ color: '#ff4d4f', marginRight: '4px', fontSize: '11px', flexShrink: 0 }} />
                    <Text style={{ fontSize: '11px' }}>{item}</Text>
                  </List.Item>
                )}
              />
            </>
          )}
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 32 }}
          description={
            <Text style={{ fontSize: '11px', color: '#999' }}>
              Chưa có điều kiện
            </Text>
          }
        />
      )}
    </Card>
  );
};

export default EntryConditions;
