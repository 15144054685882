import React from 'react';
import { Button } from 'antd';

interface QuickReply {
  text: string;
  value: string;
  icon?: React.ReactNode;
}

interface QuickRepliesProps {
  replies: QuickReply[];
  onSelect: (value: string) => void;
}

const QuickReplies: React.FC<QuickRepliesProps> = ({ onSelect, replies }) => (
  <div style={{ 
    display: 'flex', 
    gap: '8px', 
    flexWrap: 'wrap',
    marginBottom: '12px',
    padding: '0 12px'
  }}>
    {replies.map((reply, index) => (
      <Button
        key={index}
        size="small"
        icon={reply.icon}
        onClick={() => onSelect(reply.value)}
        style={{
          borderRadius: '16px',
          fontSize: '13px',
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          background: '#f5f5f5',
          border: '1px solid #d9d9d9',
          padding: '4px 12px',
          transition: 'all 0.3s ease',
        }}
        onMouseEnter={e => {
          e.currentTarget.style.transform = 'translateY(-2px)';
          e.currentTarget.style.background = '#e6f4ff';
          e.currentTarget.style.borderColor = '#1677ff';
        }}
        onMouseLeave={e => {
          e.currentTarget.style.transform = 'translateY(0)';
          e.currentTarget.style.background = '#f5f5f5';
          e.currentTarget.style.borderColor = '#d9d9d9';
        }}
      >
        {reply.text}
      </Button>
    ))}
  </div>
);

export default QuickReplies;
