import React, { useRef, useEffect } from 'react';
import { Card, Input, Button, Spin } from 'antd';
import { SendOutlined, DollarOutlined, LineChartOutlined, BarChartOutlined } from '@ant-design/icons';
import MessageBubble from './MessageBubble';
import QuickReplies from './QuickReplies';
import ChatHeader from './ChatHeader';
import { Message, QuickReply } from './types';

interface StrategyChatProps {
  messages: Message[];
  isTyping: boolean;
  isThinking: boolean;
  isOnline: boolean;
  showScrollButton: boolean;
  inputMessage: string;
  onInputChange: (message: string) => void;
  onSendMessage: () => void;
  onScrollToBottom: () => void;
  onReset: () => void;
  functionCall?: {
    name: string;
    arguments: Record<string, any>;
  } | null;
  thinkingMessage?: string | null;
}

const quickReplies: QuickReply[] = [
  { 
    text: 'Forex', 
    value: 'Tôi muốn giao dịch Forex',
    icon: <DollarOutlined />
  },
  { 
    text: 'Crypto', 
    value: 'Tôi muốn giao dịch Crypto',
    icon: <LineChartOutlined />
  },
  { 
    text: 'Cổ phiếu', 
    value: 'Tôi muốn giao dịch cổ phiếu',
    icon: <BarChartOutlined />
  },
];

const StrategyChat: React.FC<StrategyChatProps> = ({
  messages,
  isTyping,
  isThinking,
  isOnline,
  showScrollButton,
  inputMessage,
  onInputChange,
  onSendMessage,
  onScrollToBottom,
  onReset,
  functionCall,
  thinkingMessage,
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Auto scroll khi có tin nhắn mới hoặc khi typing/thinking thay đổi
  useEffect(() => {
    scrollToBottom();
  }, [messages, isTyping, isThinking]);

  const handleScroll = () => {
    if (!chatContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;

    if (isNearBottom) {
      onScrollToBottom();
    }
  };

  const renderThinkingStatus = () => {
    if (!isThinking) return null;

    let thinkingText = 'Assistant đang suy nghĩ...';
    if (thinkingMessage) {
      thinkingText = thinkingMessage;
    } else if (functionCall) {
      thinkingText = `Assistant đang thực hiện ${functionCall.name}...`;
    }

    return (
      <div style={{ 
        alignSelf: 'flex-start',
        backgroundColor: '#f5f5f5',
        padding: '8px 12px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        <Spin size="small" />
        <span>{thinkingText}</span>
      </div>
    );
  };

  return (
    <Card
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      bodyStyle={{
        height: '100%',
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChatHeader isOnline={isOnline} onReset={onReset} />
      
      <div
        ref={chatContainerRef}
        onScroll={handleScroll}
        style={{
          flex: 1,
          overflowY: 'auto',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        {messages.map((message, index) => (
          <MessageBubble
            key={index}
            message_type={message.message_type}
            content={message.content}
            strategy_update={message.strategy_update}
          />
        ))}
        {isTyping && !isThinking && (
          <div style={{ alignSelf: 'flex-start' }}>
            <Spin size="small" /> <span style={{ marginLeft: 8 }}>Assistant đang trả lời...</span>
          </div>
        )}
        {renderThinkingStatus()}
        <div ref={messagesEndRef} />
      </div>

      <QuickReplies
        replies={quickReplies}
        onSelect={(value) => {
          onInputChange(value);
          onSendMessage();
        }}
      />

      <div style={{ 
        padding: '16px', 
        borderTop: '1px solid #f0f0f0',
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'flex-end'
      }}>
        <div style={{ flex: 1 }}>
          <Input.TextArea
            placeholder="Nhập tin nhắn..."
            value={inputMessage}
            onChange={(e) => onInputChange(e.target.value)}
            autoSize={{ minRows: 1, maxRows: 4 }}
            style={{ 
              resize: 'none',
              borderRadius: '12px',
              padding: '12px'
            }}
          />
        </div>
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={onSendMessage}
          style={{
            borderRadius: '8px',
            background: 'linear-gradient(135deg, #1677ff 0%, #4096ff 100%)',
            transition: 'all 0.3s ease',
            height: '40px',
            minWidth: '80px'
          }}
        >
          Gửi
        </Button>
      </div>
    </Card>
  );
};

export default StrategyChat;
