import React, { useCallback, useMemo, useState, useEffect } from 'react';
import KlineChart from '../KlineChart';
import { generateMockKlineData } from '../../../data/mockData';
import { Alert } from 'antd';

interface KlineDataPoint {
  timestamp: number;
  open: number;
  high: number;
  low: number;
  close: number;
  volume: number;
  signal?: 'LONG' | 'SHORT' | null;
}

interface ChartModalProps {
  isVisible: boolean;
  onClose: () => void;
  chartData?: KlineDataPoint[];
}

const ChartModal: React.FC<ChartModalProps> = ({
  isVisible,
  onClose,
  chartData
}) => {
  const [showMockAlert, setShowMockAlert] = useState(false);

  // Sử dụng useMemo để cache data
  const displayData = useMemo(() => {
    const data = chartData || generateMockKlineData();
    setShowMockAlert(!chartData);
    return data;
  }, [chartData]); // Chỉ tạo data mới khi chartData thay đổi
  
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // Reset alert khi modal đóng
  useEffect(() => {
    if (!isVisible) {
      setShowMockAlert(false);
    }
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div 
      style={{ 
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 1000,
        background: '#fff'
      }}
    >
      {showMockAlert && (
        <Alert
          message="Đang sử dụng dữ liệu mẫu"
          description="Không có dữ liệu thực từ kết quả backtest, đang hiển thị dữ liệu mẫu để minh họa."
          type="info"
          showIcon
          closable
          style={{ 
            position: 'absolute',
            top: 20,
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1001,
            maxWidth: '90%',
            width: 'auto'
          }}
        />
      )}
      <KlineChart data={displayData} onClose={handleClose} />
    </div>
  );
};

export default ChartModal;
