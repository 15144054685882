import React from 'react';
import { Badge, Typography, Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

interface ChatHeaderProps {
  isOnline: boolean;
  onReset: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ isOnline, onReset }) => {
  return (
    <div style={{ 
      padding: '16px 24px',
      borderBottom: '1px solid #f0f0f0',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      background: '#fff',
      borderRadius: '16px 16px 0 0',
      position: 'sticky',
      top: 0,
      zIndex: 1,
      backdropFilter: 'blur(8px)',
    }}>
      <Badge status={isOnline ? "processing" : "default"} />
      <div style={{ flex: 1 }}>
        <Title level={5} style={{ margin: 0 }}>AI Assistant</Title>
        <Text type="secondary" style={{ fontSize: '12px' }}>
          {isOnline ? 'Online - Sẵn sàng hỗ trợ' : 'Offline - Đang kết nối lại'}
        </Text>
      </div>
      <Button 
        icon={<ReloadOutlined />}
        onClick={onReset}
        type="text"
        title="Reset chat"
      >
        Reset
      </Button>
    </div>
  );
};

export default ChatHeader;
