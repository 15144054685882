import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SpiderRadarChart: React.FC = () => {
  const options = {
    chart: {
      polar: true,
      type: 'line',
      width: 300,
      height: 300,
      spacing: [10, 10, 10, 20], // Tăng spacing bên trái lên 20px
      marginLeft: 30, // Thêm margin bên trái
      alignTicks: false,
      plotPlacement: 'center' // Đảm bảo plot được căn giữa
    },
    title: {
      text: 'Spider/Radar Chart',
      style: {
        fontSize: '14px'
      },
      align: 'center',
      margin: 15 // Tăng margin cho title
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      size: '75%', // Giảm size xuống một chút
      center: ['55%', '50%'] // Di chuyển center sang phải một chút
    },
    xAxis: {
      categories: ['Profitability', 'Risk Control', 'University', 'Diversity', 'Reliability', 'Explainability'],
      tickmarkPlacement: 'on',
      lineWidth: 0,
      labels: {
        style: {
          fontSize: '10px'
        },
        distance: 10 // Tăng khoảng cách của labels
      }
    },
    yAxis: {
      gridLineInterpolation: 'polygon',
      lineWidth: 0,
      min: 0,
      labels: {
        style: {
          fontSize: '10px'
        }
      }
    },
    series: [{
      name: 'Mock Data',
      data: [65, 59, 90, 81, 56, 55],
      pointPlacement: 'on',
    }],
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    }
  };

  return (
    <div style={{ 
      margin: '10px',
      padding: '0 0 0 15px', // Thêm padding bên trái
      display: 'flex',
      justifyContent: 'center' // Căn giữa chart
    }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default SpiderRadarChart;
