export const chartData = [
    [1628515800000, 146.2, 146.7, 145.52, 146.09, 48908700],
    [1628602200000, 146.44, 147.71, 145.3, 145.6, 69023100],
    [1628688600000, 146.05, 146.72, 145.53, 145.86, 48493500],
    [1628775000000, 146.19, 149.05, 145.84, 148.89, 72282600],
    [1628861400000, 148.97, 149.44, 148.27, 149.1, 59375000],
    [1629120600000, 148.54, 151.19, 146.47, 151.12, 103296000],
    [1629207000000, 150.23, 151.68, 149.09, 150.19, 92229700],
    [1629293400000, 149.8, 150.72, 146.15, 146.36, 86326000],
    [1629379800000, 145.03, 148, 144.5, 146.7, 86960300],
    [1629466200000, 147.44, 148.5, 146.78, 148.19, 60549600],
    [1629725400000, 148.31, 150.19, 147.89, 149.71, 60131800],
    [1629811800000, 149.45, 150.86, 149.15, 149.62, 48606400],
    [1629898200000, 149.81, 150.32, 147.8, 148.36, 58991300],
    [1629984600000, 148.35, 149.12, 147.51, 147.54, 48597200],
    [1630071000000, 147.48, 148.75, 146.83, 148.6, 55802400],
    [1630330200000, 149, 153.49, 148.61, 153.12, 90956700],
    [1630416600000, 152.66, 152.8, 151.29, 151.83, 86453100],
    [1630503000000, 152.83, 154.98, 152.34, 152.51, 80313700],
    [1630589400000, 153.87, 154.72, 152.4, 153.65, 71115500],
    [1630675800000, 153.76, 154.63, 153.09, 154.3, 57808700],
    [1631021400000, 154.97, 157.26, 154.39, 156.69, 82278300],
    [1631107800000, 156.98, 157.04, 153.98, 155.11, 74420200],
    [1631194200000, 155.49, 156.11, 153.95, 154.07, 57305700],
    [1631280600000, 155, 155.48, 148.7, 148.97, 140893200],
    [1631539800000, 150.63, 151.42, 148.75, 149.55, 102404300],
    [1631626200000, 150.35, 151.07, 146.91, 148.12, 109296300],
    [1631712600000, 148.56, 149.44, 146.37, 149.03, 83281300],
    [1631799000000, 148.44, 148.97, 147.22, 148.79, 68034100],
    [1631885400000, 148.82, 148.82, 145.76, 146.06, 129868800],
    [1632144600000, 143.8, 144.84, 141.27, 142.94, 123478900],
    [1632231000000, 143.93, 144.6, 142.78, 143.43, 75834000],
    [1632317400000, 144.45, 146.43, 143.7, 145.85, 76404300],
    [1632403800000, 146.65, 147.08, 145.64, 146.83, 64838200],
    [1632490200000, 145.66, 147.47, 145.56, 146.92, 53477900],
    [1632749400000, 145.47, 145.96, 143.82, 145.37, 74150700],
    [1632835800000, 143.25, 144.75, 141.69, 141.91, 108972300],
    [1632922200000, 142.47, 144.45, 142.03, 142.83, 74602000],
    [1633008600000, 143.66, 144.38, 141.28, 141.5, 89056700],
    [1633095000000, 141.9, 142.92, 139.11, 142.65, 94639600],
    [1633354200000, 141.76, 142.21, 138.27, 139.14, 98322000],
    [1633440600000, 139.49, 142.24, 139.36, 141.11, 80861100],
    [1633527000000, 139.47, 142.15, 138.37, 142, 83221100],
    [1633613400000, 143.06, 144.22, 142.72, 143.29, 61732700],
    [1633699800000, 144.03, 144.18, 142.56, 142.9, 58773200],
    [1633959000000, 142.27, 144.81, 141.81, 142.81, 64452200],
    [1634045400000, 143.23, 143.25, 141.04, 141.51, 73035900],
    [1634131800000, 141.24, 141.4, 139.2, 140.91, 78762700],
    [1634218200000, 142.11, 143.88, 141.51, 143.76, 69907100],
    [1634304600000, 143.77, 144.9, 143.51, 144.84, 67940300],
    [1634563800000, 143.45, 146.84, 143.16, 146.55, 85589200],
    [1634650200000, 147.01, 149.17, 146.55, 148.76, 76378900],
    [1634736600000, 148.7, 149.75, 148.12, 149.26, 58418800],
    [1634823000000, 148.81, 149.64, 147.87, 149.48, 61421000],
    [1634909400000, 149.69, 150.18, 148.64, 148.69, 58883400],
    [1635168600000, 148.68, 149.37, 147.62, 148.64, 50720600],
    [1635255000000, 149.33, 150.84, 149.01, 149.32, 60893400],
    [1635341400000, 149.36, 149.73, 148.49, 148.85, 56094900],
    [1635427800000, 149.82, 153.17, 149.72, 152.57, 100077900],
    [1635514200000, 147.22, 149.94, 146.41, 149.8, 124953200],
    [1635773400000, 148.99, 149.7, 147.8, 148.96, 74588300],
    [1635859800000, 148.66, 151.57, 148.65, 150.02, 69122000],
    [1635946200000, 150.39, 151.97, 149.82, 151.49, 54511500],
    [1636032600000, 151.58, 152.43, 150.64, 150.96, 60394600],
    [1636119000000, 151.89, 152.2, 150.06, 151.28, 65463900],
    [1636381800000, 151.41, 151.57, 150.16, 150.44, 55020900],
    [1636468200000, 150.2, 151.43, 150.06, 150.81, 56787900],
    [1636554600000, 150.02, 150.13, 147.85, 147.92, 65187100],
    [1636641000000, 148.96, 149.43, 147.68, 147.87, 41000000],
    [1636727400000, 148.43, 150.4, 147.48, 149.99, 63804000],
    [1636986600000, 150.37, 151.88, 149.43, 150, 59222800],
    [1637073000000, 149.94, 151.49, 149.34, 151, 59256200],
    [1637159400000, 151, 155, 150.99, 153.49, 88807000],
    [1637245800000, 153.71, 158.67, 153.05, 157.87, 137827700],
    [1637332200000, 157.65, 161.02, 156.53, 160.55, 117305600],
    [1637591400000, 161.68, 165.7, 161, 161.02, 117467900],
    [1637677800000, 161.12, 161.8, 159.06, 161.41, 96041900],
    [1637764200000, 160.75, 162.14, 159.64, 161.94, 69463600],
    [1637937000000, 159.57, 160.45, 156.36, 156.81, 76959800],
    [1638196200000, 159.37, 161.19, 158.79, 160.24, 88748200],
    [1638282600000, 159.99, 165.52, 159.92, 165.3, 174048100],
    [1638369000000, 167.48, 170.3, 164.53, 164.77, 152052500],
    [1638455400000, 158.74, 164.2, 157.8, 163.76, 136739200],
    [1638541800000, 164.02, 164.96, 159.72, 161.84, 118023100],
    [1638801000000, 164.29, 167.88, 164.28, 165.32, 107497000],
    [1638887400000, 169.08, 171.58, 168.34, 171.18, 120405400],
    [1638973800000, 172.13, 175.96, 170.7, 175.08, 116998900],
    [1639060200000, 174.91, 176.75, 173.92, 174.56, 108923700],
    [1639146600000, 175.21, 179.63, 174.69, 179.45, 115402700],
    [1639405800000, 181.12, 182.13, 175.53, 175.74, 153237000],
    [1639492200000, 175.25, 177.74, 172.21, 174.33, 139380400],
    [1639578600000, 175.11, 179.5, 172.31, 179.3, 131063300],
    [1639665000000, 179.28, 181.14, 170.75, 172.26, 150185800],
    [1639751400000, 169.93, 173.47, 169.69, 171.14, 195432700],
    [1640010600000, 168.28, 170.58, 167.46, 169.75, 107499100],
    [1640097000000, 171.56, 173.2, 169.12, 172.99, 91185900],
    [1640183400000, 173.04, 175.86, 172.15, 175.64, 92135300],
    [1640269800000, 175.85, 176.85, 175.27, 176.28, 68356600],
    [1640615400000, 177.09, 180.42, 177.07, 180.33, 74919600],
    [1640701800000, 180.16, 181.33, 178.53, 179.29, 79144300],
    [1640788200000, 179.33, 180.63, 178.14, 179.38, 62348900],
    [1640874600000, 179.47, 180.57, 178.09, 178.2, 59773000],
    [1640961000000, 178.09, 179.23, 177.26, 177.57, 64062300],
    [1641220200000, 177.83, 182.88, 177.71, 182.01, 104487900],
    [1641306600000, 182.63, 182.94, 179.12, 179.7, 99310400],
    [1641393000000, 179.61, 180.17, 174.64, 174.92, 94537600],
    [1641479400000, 172.7, 175.3, 171.64, 172, 96904000],
    [1641565800000, 172.89, 174.14, 171.03, 172.17, 86709100],
    [1641825000000, 169.08, 172.5, 168.17, 172.19, 106765600],
    [1641911400000, 172.32, 175.18, 170.82, 175.08, 76138300],
    [1641997800000, 176.12, 177.18, 174.82, 175.53, 74805200],
    [1642084200000, 175.78, 176.62, 171.79, 172.19, 84505800],
    [1642170600000, 171.34, 173.78, 171.09, 173.07, 80440800],
    [1642516200000, 171.51, 172.54, 169.41, 169.8, 90956700],
    [1642602600000, 170, 171.08, 165.94, 166.23, 94815000],
    [1642689000000, 166.98, 169.68, 164.18, 164.51, 91420500],
    [1642775400000, 164.42, 166.33, 162.3, 162.41, 122848900],
    [1643034600000, 160.02, 162.3, 154.7, 161.62, 162294600],
    [1643121000000, 158.98, 162.76, 157.02, 159.78, 115798400],
    [1643207400000, 163.5, 164.39, 157.82, 159.69, 108275300],
    [1643293800000, 162.45, 163.84, 158.28, 159.22, 121954600],
    [1643380200000, 165.71, 170.35, 162.8, 170.33, 179935700]
];
