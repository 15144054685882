import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Input, message, Space, Card, Form } from 'antd';
import { PlusOutlined, BuildOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import TradingService from '../services/trading.service';
import { Strategy, CreateStrategyRequest } from '../types/api.types';

const { TextArea } = Input;

const StrategyList: React.FC = () => {
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [selectedStrategyId, setSelectedStrategyId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [shareForm] = Form.useForm();
  const navigate = useNavigate();

  const fetchStrategies = async () => {
    try {
      setLoading(true);
      const response = await TradingService.getStrategies();
      if (response.success && response.data) {
        setStrategies(response.data);
      } else {
        message.error('Không thể tải danh sách chiến lược');
      }
    } catch (error) {
      message.error('Lỗi khi tải danh sách chiến lược');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStrategies();
  }, []);

  const handleCreateStrategy = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      const createStrategyRequest: CreateStrategyRequest = {
        name: values.name,
        description: values.description || '',
        parameters: {}
      };

      setLoading(true);
      const response = await TradingService.createStrategy(createStrategyRequest);
      
      if (response.success && response.data) {
        message.success('Tạo chiến lược thành công');
        setIsModalVisible(false);
        form.resetFields();
        fetchStrategies();
      } else {
        message.error(response.error || 'Có lỗi xảy ra khi tạo chiến lược');
      }
    } catch (error) {
      message.error('Vui lòng điền đầy đủ thông tin');
    } finally {
      setLoading(false);
    }
  };

  const handleBuildStrategy = (strategyId: string) => {
    navigate(`/strategy/build/${strategyId}`);
  };

  const handleShare = (strategyId: string) => {
    setSelectedStrategyId(strategyId);
    setIsShareModalVisible(true);
  };

  const handleShareModalOk = async () => {
    try {
      const values = await shareForm.validateFields();
      setLoading(true);
      const response = await TradingService.shareStrategy(selectedStrategyId, values.email);
      
      if (response.success) {
        message.success('Chia sẻ chiến lược thành công');
        setIsShareModalVisible(false);
        shareForm.resetFields();
      } else {
        message.error(response.error || 'Có lỗi xảy ra khi chia sẻ chiến lược');
      }
    } catch (error) {
      message.error('Vui lòng nhập email hợp lệ');
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString: string) => {
    try {
      if (!dateString) return 'Chưa có ngày';
      
      const date = new Date(dateString);
      if (isNaN(date.getTime())) return 'Ngày không hợp lệ';

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${hours}:${minutes} ${day}/${month}/${year}`;
    } catch {
      return 'Ngày không hợp lệ';
    }
  };

  const columns: ColumnsType<Strategy> = [
    {
      title: 'Tên chiến lược',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      width: '35%',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      width: '20%',
      render: (date: string) => formatDate(date)
    },
    {
      title: 'Hành động',
      key: 'action',
      width: '20%',
      render: (_, record: Strategy) => (
        <Space size="small">
          <Button
            type="primary"
            icon={<BuildOutlined />}
            onClick={() => handleBuildStrategy(record.id)}
          >
            Build
          </Button>
          <Button
            type="default"
            icon={<ShareAltOutlined />}
            onClick={() => handleShare(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          type="primary" 
          icon={<PlusOutlined />} 
          onClick={handleCreateStrategy}
        >
          Tạo chiến lược
        </Button>
      </div>

      <Card title="Danh sách chiến lược">
        <Table
          columns={columns}
          dataSource={strategies}
          rowKey="id"
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Tổng số ${total} chiến lược`,
          }}
          scroll={{ x: true }}
        />
      </Card>

      <Modal
        title="Tạo chiến lược mới"
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
        confirmLoading={loading}
      >
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="name"
            label="Tên chiến lược"
            rules={[{ required: true, message: 'Vui lòng nhập tên chiến lược' }]}
          >
            <Input placeholder="Nhập tên chiến lược" />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
          >
            <TextArea 
              placeholder="Nhập mô tả chiến lược (không bắt buộc)" 
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Chia sẻ chiến lược"
        open={isShareModalVisible}
        onOk={handleShareModalOk}
        onCancel={() => {
          setIsShareModalVisible(false);
          shareForm.resetFields();
        }}
        confirmLoading={loading}
      >
        <Form
          form={shareForm}
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Vui lòng nhập email' },
              { type: 'email', message: 'Email không hợp lệ' }
            ]}
          >
            <Input placeholder="Nhập email người nhận" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StrategyList;
