import React from 'react';
import { Modal, List, Tag, Typography } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface Trade {
  date: string;
  type: 'buy' | 'sell';
  profit: number | string;
}

interface TradeHistoryModalProps {
  isVisible: boolean;
  onClose: () => void;
  trades?: Trade[];
}

const TradeHistoryModal: React.FC<TradeHistoryModalProps> = ({
  isVisible,
  onClose,
  trades = []
}) => {
  const formatProfit = (profit: number | string): string => {
    const numericProfit = typeof profit === 'string' ? parseFloat(profit) : profit;
    return numericProfit.toFixed(2);
  };

  return (
    <Modal
      title="Lịch sử giao dịch"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <List
        size="small"
        dataSource={trades}
        renderItem={trade => {
          const numericProfit = typeof trade.profit === 'string' ? parseFloat(trade.profit) : trade.profit;
          return (
            <List.Item style={{ padding: '8px 0' }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: '12px',
                color: numericProfit >= 0 ? '#52c41a' : '#ff4d4f',
                fontSize: '14px'
              }}>
                {numericProfit >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                <Text style={{ fontSize: '14px' }}>{trade.date}</Text>
                <Tag color={trade.type === 'buy' ? 'blue' : 'orange'} style={{ fontSize: '13px', margin: 0 }}>
                  {trade.type === 'buy' ? 'MUA' : 'BÁN'}
                </Tag>
                <Text strong style={{ fontSize: '14px' }}>${formatProfit(trade.profit)}</Text>
              </div>
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default TradeHistoryModal;
