import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatState } from '../types/chat.types';
import { Message, ChatResponse } from '../../components/strategy/types';
import { updateStrategyFromResponse } from './strategySlice';
import { AppDispatch } from '../index';

const initialState: ChatState = {
  messages: [{
    id: 0,
    user: 0,
    username: 'Assistant',
    strategy: 0,
    strategy_name: '',
    content: `# Xin chào! 👋
      
Tôi có thể giúp bạn xây dựng chiến lược giao dịch. Hãy cho tôi biết:

1. **Loại tài sản** bạn muốn giao dịch?
2. **Khung thời gian** giao dịch?
3. **Mức độ rủi ro** bạn chấp nhận được?
4. **Các chỉ báo kỹ thuật** bạn quen thuộc?`,
    message_type: 'assistant',
    created_at: new Date().toISOString(),
  }],
  inputMessage: '',
  isTyping: false,
  isThinking: false,
  isOnline: false,
  showScrollButton: false,
  error: null,
  functionCall: null,
  thinkingMessage: null,
};

export const handleChatResponseThunk = (response: ChatResponse) => (dispatch: AppDispatch) => {
  dispatch(handleChatResponse(response));
  
  if (response.type === 'completed' && response.entryConditions && response.exitConditions) {
    dispatch(updateStrategyFromResponse({
      entryConditions: response.entryConditions,
      exitConditions: response.exitConditions,
      indicators: response.indicators
    }));
  }
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setInputMessage: (state, action: PayloadAction<string>) => {
      state.inputMessage = action.payload;
    },
    setIsTyping: (state, action: PayloadAction<boolean>) => {
      state.isTyping = action.payload;
    },
    setIsThinking: (state, action: PayloadAction<boolean>) => {
      state.isThinking = action.payload;
    },
    setIsOnline: (state, action: PayloadAction<boolean>) => {
      state.isOnline = action.payload;
    },
    setShowScrollButton: (state, action: PayloadAction<boolean>) => {
      state.showScrollButton = action.payload;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    setMessages: (state, action: PayloadAction<Message[]>) => {
      state.messages = action.payload;
    },
    setThinkingMessage: (state, action: PayloadAction<string | null>) => {
      state.thinkingMessage = action.payload;
    },
    handleChatResponse: (state, action: PayloadAction<ChatResponse>) => {
      const response = action.payload;
      console.log('Handling chat response:', response);
      
      // Xử lý message từ MQTT về việc tạo strategy
      if (response.type === 'created') {
        state.isThinking = true;
        state.thinkingMessage = 'Tạo thành công đang tổng hợp';
        console.log('Setting thinking message:', state.thinkingMessage);

        // Xử lý error nếu có
        if (response.error) {
          state.error = response.error;
        }

        return;
      }

      // Xử lý khi hoàn thành tạo chiến lược
      if (response.type === 'completed') {
        state.isThinking = false;
        state.thinkingMessage = null;

        // Thêm message mới với thông tin chi tiết về chiến lược
        const strategyMessage: Message = {
          id: state.messages.length + 1,
          user: 0,
          username: 'Assistant',
          strategy: 0,
          strategy_name: '',
          content: response.strategy_summary || 'Chiến lược đã được tạo thành công',
          message_type: 'assistant',
          created_at: new Date().toISOString(),
        };

        state.messages.push(strategyMessage);
        return;
      }

      // Xử lý các response thông thường
      if (response.status === 'thinking') {
        state.isThinking = true;
        if (response.function_call) {
          state.functionCall = response.function_call;
        }
        // Cập nhật thinkingMessage nếu có
        if (response.message) {
          state.thinkingMessage = response.message;
          console.log('Setting thinking message from thinking status:', response.message);
        }
      } else if (response.status === 'success') {
        if (response.ai_message) {
          state.messages.push(response.ai_message);
        }
        state.isThinking = false;
        state.functionCall = null;
        state.thinkingMessage = null;
      }

      state.isTyping = false;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setInputMessage,
  setIsTyping,
  setIsThinking,
  setIsOnline,
  setShowScrollButton,
  addMessage,
  setMessages,
  handleChatResponse,
  setError,
  setThinkingMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
