import React from 'react';
import { Card } from 'antd';
import ReactMarkdown from 'react-markdown';
import { UIStrategy } from './types';

interface MessageBubbleProps {
  message_type: 'user' | 'assistant';
  content: string;
  strategy_update?: UIStrategy;
}

const MessageBubble: React.FC<MessageBubbleProps> = ({
  message_type,
  content,
  strategy_update,
}) => {
  const isAssistant = message_type === 'assistant';

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: isAssistant ? 'flex-start' : 'flex-end',
        marginBottom: '8px',
      }}
    >
      <Card
        style={{
          maxWidth: '80%',
          backgroundColor: isAssistant ? '#f0f2f5' : '#1677ff',
          borderColor: isAssistant ? '#e4e6eb' : '#1677ff',
          color: isAssistant ? '#000' : '#fff',
        }}
        bodyStyle={{ 
          padding: '12px',
          color: isAssistant ? '#000' : '#fff'
        }}
      >
        <div style={{ color: isAssistant ? '#000' : '#fff' }}>
          <ReactMarkdown>{content}</ReactMarkdown>
        </div>
        {strategy_update && (
          <div style={{ 
            marginTop: '8px', 
            fontSize: '12px', 
            color: isAssistant ? '#888' : '#e6f4ff' 
          }}>
            Chiến lược đã được cập nhật
          </div>
        )}
      </Card>
    </div>
  );
};

export default MessageBubble;
