import { API_CONFIG } from '../config/api.config';
import { ApiResponse, LoginRequest, LoginResponse, RegisterRequest, RegisterResponse } from '../types/api.types';

class AuthService {
    static async login(credentials: LoginRequest): Promise<ApiResponse<LoginResponse>> {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/users/api/token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(credentials),
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.detail || 'Login failed'
                };
            }

            localStorage.setItem('token', data.access);
            localStorage.setItem('refreshToken', data.refresh);
            localStorage.setItem('username', credentials.username);

            return {
                success: true,
                data: {
                    ...data,
                    username: credentials.username
                }
            };
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    static async register(data: RegisterRequest): Promise<ApiResponse<RegisterResponse>> {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/users/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify(data),
            });

            const responseData = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: responseData.detail || 'Registration failed'
                };
            }

            return {
                success: true,
                data: responseData
            };
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    static async refreshToken(refreshToken: string): Promise<ApiResponse<LoginResponse>> {
        try {
            const response = await fetch(`${API_CONFIG.BASE_URL}/api/users/token/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            const data = await response.json();

            if (!response.ok) {
                return {
                    success: false,
                    error: data.detail || 'Token refresh failed'
                };
            }

            localStorage.setItem('token', data.access);

            return {
                success: true,
                data: {
                    ...data,
                    username: this.getUsername() || ''
                }
            };
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    static async logout(): Promise<ApiResponse<void>> {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('username');
            return {
                success: true
            };
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Logout failed'
            };
        }
    }

    static isAuthenticated(): boolean {
        return !!localStorage.getItem('token');
    }

    static getToken(): string | null {
        return localStorage.getItem('token');
    }

    static getRefreshToken(): string | null {
        return localStorage.getItem('refreshToken');
    }

    static getUsername(): string | null {
        return localStorage.getItem('username');
    }
}

export default AuthService;
