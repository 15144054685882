import { API_CONFIG } from '../config/api.config';
import { ApiResponse } from '../types/api.types';
import AuthService from './auth.service';

class ApiService {
    private static isRefreshing = false;
    private static refreshSubscribers: ((token: string) => void)[] = [];

    private static subscribeTokenRefresh(callback: (token: string) => void) {
        this.refreshSubscribers.push(callback);
    }

    private static onTokenRefreshed(token: string) {
        this.refreshSubscribers.forEach(callback => callback(token));
        this.refreshSubscribers = [];
    }

    private static async getHeaders(): Promise<Record<string, string>> {
        const headers: Record<string, string> = { ...API_CONFIG.HEADERS };
        const token = localStorage.getItem('token');
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
        return headers;
    }

    private static async handleTokenError(error: any): Promise<string> {
        if (this.isRefreshing) {
            return new Promise(resolve => {
                this.subscribeTokenRefresh(token => {
                    resolve(token);
                });
            });
        }

        this.isRefreshing = true;

        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) {
                throw new Error('No refresh token available');
            }

            const response = await AuthService.refreshToken(refreshToken);
            if (response.success && response.data) {
                const { access } = response.data;
                localStorage.setItem('token', access);
                this.onTokenRefreshed(access);
                this.isRefreshing = false;
                return access;
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            this.isRefreshing = false;
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login';
            throw error;
        }
    }

    private static async handleResponse<T>(response: Response): Promise<ApiResponse<T>> {
        if (response.status === 401) {
            try {
                await this.handleTokenError(response);
                // Retry the original request with new token
                const newHeaders = await this.getHeaders();
                const newResponse = await fetch(response.url, {
                    ...response,
                    headers: newHeaders
                });
                return this.handleResponse<T>(newResponse);
            } catch (error) {
                return {
                    success: false,
                    error: 'Authentication failed'
                };
            }
        }

        // Xử lý response 204 No Content
        if (response.status === 204) {
            return {
                success: true
            };
        }

        if (!response.ok) {
            const error = await response.json();
            return {
                success: false,
                error: error.detail || error.message || 'Có lỗi xảy ra'
            };
        }

        const data = await response.json();
        return {
            success: true,
            data
        };
    }

    protected static async get<T>(endpoint: string): Promise<ApiResponse<T>> {
        try {
            const headers = await this.getHeaders();
            const response = await fetch(`${API_CONFIG.BASE_URL}${endpoint}`, {
                method: 'GET',
                headers
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    protected static async post<T>(endpoint: string, body: any): Promise<ApiResponse<T>> {
        try {
            const headers = await this.getHeaders();
            const response = await fetch(`${API_CONFIG.BASE_URL}${endpoint}`, {
                method: 'POST',
                headers,
                body: JSON.stringify(body)
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    protected static async put<T>(endpoint: string, body: any): Promise<ApiResponse<T>> {
        try {
            const headers = await this.getHeaders();
            const response = await fetch(`${API_CONFIG.BASE_URL}${endpoint}`, {
                method: 'PUT',
                headers,
                body: JSON.stringify(body)
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }

    protected static async delete<T>(endpoint: string): Promise<ApiResponse<T>> {
        try {
            const headers = await this.getHeaders();
            const response = await fetch(`${API_CONFIG.BASE_URL}${endpoint}`, {
                method: 'DELETE',
                headers
            });
            return this.handleResponse<T>(response);
        } catch (error) {
            return {
                success: false,
                error: error instanceof Error ? error.message : 'Network error'
            };
        }
    }
}

export default ApiService;
