import { useEffect, useCallback, useRef } from 'react';
import { useAppDispatch, useAppSelector } from './';
import {
  setInputMessage,
  setShowScrollButton,
  handleChatResponseThunk,
  setMessages,
  setError,
  setIsTyping,
  setIsOnline,
  addMessage,
  setThinkingMessage,
  setIsThinking,
} from '../store/slices/chatSlice';
import ChatService from '../services/chat.service';
import MQTTService from '../services/mqtt.service';
import { Message } from '../components/strategy/types';
import { useAuth } from '../contexts/AuthContext';

export const useStrategyChat = (strategyId: number) => {
  const dispatch = useAppDispatch();
  const { username } = useAuth();
  const {
    messages,
    inputMessage,
    isTyping,
    isThinking,
    isOnline,
    showScrollButton,
    functionCall,
    thinkingMessage,
  } = useAppSelector(state => state.chat);

  // Sử dụng useRef để lưu trữ MQTT service instance và callback
  const mqttService = useRef<MQTTService | null>(null);
  const notificationCallback = useRef<((data: any) => void) | null>(null);
  const retryCount = useRef(0);
  const maxRetries = 3;
  const cleanupRef = useRef<(() => void) | null>(null);

  const handleMQTTMessage = useCallback((data: any) => {
    console.log('Received MQTT message:', data);

    // Xử lý message từ MQTT về việc tạo strategy
    if (data.type === 'created') {
      dispatch(setIsThinking(true));
      if (data.message) {
        console.log('Setting thinking message from MQTT:', data.message);
        dispatch(setThinkingMessage(data.message));
      }
      dispatch(handleChatResponseThunk(data));
      return;
    }
    
    // Xử lý các response thông thường
    dispatch(handleChatResponseThunk(data));
  }, [dispatch]);

  // Khởi tạo MQTT service và thiết lập kết nối
  const initializeMQTT = useCallback(async () => {
    try {
      if (!mqttService.current) {
        console.log('Initializing MQTT service...');
        mqttService.current = MQTTService.getInstance();
      }

      // Đăng ký callback để nhận thông báo khi trạng thái kết nối thay đổi
      const cleanup = mqttService.current.onConnectionChange((isConnected) => {
        console.log('MQTT connection status changed:', isConnected);
        dispatch(setIsOnline(isConnected));
        
        // Nếu kết nối thành công và có username, subscribe notifications
        if (isConnected && username && !notificationCallback.current) {
          console.log('Connection successful, subscribing to chat...');
          notificationCallback.current = handleMQTTMessage;
          mqttService.current?.subscribeToStrategyChat(username, strategyId, handleMQTTMessage);
          retryCount.current = 0; // Reset retry count on successful connection
        }
      });

      cleanupRef.current = cleanup;
    } catch (error) {
      console.error('Error initializing MQTT:', error);
      
      // Retry logic
      if (retryCount.current < maxRetries) {
        console.log(`Retrying MQTT initialization (${retryCount.current + 1}/${maxRetries})...`);
        retryCount.current++;
        
        // Exponential backoff: 2s, 4s, 8s
        const delay = Math.pow(2, retryCount.current) * 1000;
        setTimeout(() => {
          initializeMQTT();
        }, delay);
      } else {
        console.error('Max retries reached for MQTT initialization');
        dispatch(setError('Không thể kết nối tới MQTT server sau nhiều lần thử'));
      }
    }
  }, [dispatch, username, strategyId, handleMQTTMessage]);

  // Khởi tạo MQTT service khi component mount hoặc username/strategyId thay đổi
  useEffect(() => {
    // Chỉ khởi tạo nếu có username
    if (!username) return;

    initializeMQTT();

    return () => {
      // Gọi cleanup function nếu có
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }

      // Cleanup khi component unmount hoặc username thay đổi
      if (notificationCallback.current && username && mqttService.current) {
        console.log('Unsubscribing from MQTT...');
        mqttService.current.unsubscribeFromStrategyChat(username, strategyId, notificationCallback.current);
        notificationCallback.current = null;
      }
      mqttService.current = null;
    };
  }, [username, strategyId, initializeMQTT]);

  useEffect(() => {
    const loadChatHistory = async () => {
      try {
        const response = await ChatService.getChatHistory(strategyId);
        dispatch(setMessages(response.data));
      } catch (error) {
        console.error('Error loading chat history:', error);
        dispatch(setError('Không thể tải lịch sử chat'));
      }
    };

    loadChatHistory();
  }, [strategyId, dispatch]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const messageContent = inputMessage;
    
    // Thêm tin nhắn user vào state ngay lập tức
    const userMessage: Message = {
      id: Date.now(),
      user: 0,
      username: username || 'User',
      strategy: strategyId,
      strategy_name: '',
      content: messageContent,
      message_type: 'user',
      created_at: new Date().toISOString(),
    };
    dispatch(addMessage(userMessage));
    
    // Clear input và set trạng thái
    dispatch(setInputMessage(''));
    dispatch(setIsTyping(true));

    try {
      const response = await ChatService.sendMessage(strategyId, messageContent);
      
      if (response.data.status === 'success') {
        // Chỉ xử lý response.ai_message, bỏ qua response.user_message
        dispatch(handleChatResponseThunk(response.data));
      } else if (response.data.status === 'thinking') {
        // Nếu status là thinking, đợi kết quả từ MQTT
        dispatch(handleChatResponseThunk(response.data));
      }
    } catch (error) {
      console.error('Error sending message:', error);
      dispatch(setError('Không thể gửi tin nhắn'));
      dispatch(setIsTyping(false));
    }
  };

  const handleResetChat = async () => {
    try {
      await ChatService.resetChat(strategyId);
      // Reset về tin nhắn mặc định
      dispatch(setMessages([{
        id: 0,
        user: 0,
        username: 'Assistant',
        strategy: strategyId,
        strategy_name: '',
        content: `# Xin chào! 👋
      
Tôi có thể giúp bạn xây dựng chiến lược giao dịch. Hãy cho tôi biết:

1. **Loại tài sản** bạn muốn giao dịch?
2. **Khung thời gian** giao dịch?
3. **Mức độ rủi ro** bạn chấp nhận được?
4. **Các chỉ báo kỹ thuật** bạn quen thuộc?`,
        message_type: 'assistant',
        created_at: new Date().toISOString(),
      }]));
    } catch (error) {
      console.error('Error resetting chat:', error);
      dispatch(setError('Không thể reset chat'));
    }
  };

  return {
    messages,
    inputMessage,
    isTyping,
    isThinking,
    isOnline,
    showScrollButton,
    functionCall,
    thinkingMessage,
    setInputMessage: (message: string) => dispatch(setInputMessage(message)),
    setShowScrollButton: (show: boolean) => dispatch(setShowScrollButton(show)),
    handleSendMessage,
    handleResetChat,
  };
};
