import React, { useEffect, useState, useRef } from 'react';
import { init, dispose, Chart, registerStyles, registerIndicator, KLineData as KLineDataType } from 'klinecharts';
import { Button, Space, Tooltip } from 'antd';
import { FullscreenOutlined, CameraOutlined, FieldTimeOutlined, LineChartOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';

interface KlineChartProps {
  data: Array<{
    timestamp: number;
    open: number;
    high: number;
    low: number;
    close: number;
    volume: number;
    signal?: string | null;
  }>;
  trades?: {
    time: string;
    price: number;
    type: string;
  }[];
  onClose?: () => void;
}

interface ArrowPosition {
  x: number;
  y: number;
  type: 'LONG' | 'SHORT';
  entryPrice: string;
  exitPrice: string;
  profit: string;
}

const green = 'rgb(111, 183, 111)';
const red = 'rgb(255, 111, 111)';
const black = 'rgb(0, 0, 0)';
const signalBlue = '#2862ff';
const signalRed = '#ff1944';

let globalArrowPositions: ArrowPosition[] = [];

registerIndicator({
  name: 'SIGNAL',
  figures: [{ key: 'signal' }],
  calc: (dataList: KLineDataType[]) => {
    return dataList.map((kLineData: any) => {
      const signal = kLineData.signal;
      
      return {
        signal: signal ? kLineData.close : null,
        text: signal,
        position: signal === 'LONG' ? 'bottom' : 'top',
        color: signal === 'LONG' ? signalBlue : signal === 'SHORT' ? signalRed : null,
        entryPrice: kLineData.close?.toFixed(2) || '0',
        exitPrice: kLineData.close?.toFixed(2) || '0',
        profit: '0'
      };
    });
  },
  draw: ({
    ctx,
    visibleRange,
    indicator,
    xAxis,
    yAxis
  }) => {
    const { from, to } = visibleRange;
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.lineWidth = 1;
    ctx.setLineDash([]);

    globalArrowPositions = [];

    const result = indicator.result;
    for (let i = from; i < to; i++) {
      const data = result[i];
      if (data?.signal !== null && data?.text) {
        const x = xAxis.convertToPixel(i);
        const signalY = yAxis.convertToPixel(data.signal);
        
        if (data.text === 'LONG') {
          const arrowY = signalY + 25; // Giảm từ 50 xuống 25
          ctx.fillStyle = signalBlue;
          
          // Vẽ mũi tên
          ctx.beginPath();
          ctx.moveTo(x, arrowY - 10);
          ctx.lineTo(x - 5, arrowY);
          ctx.lineTo(x + 5, arrowY);
          ctx.closePath();
          ctx.fill();
          
          // Vẽ text
          ctx.font = '12px Arial';
          ctx.fillText('LONG', x, arrowY + 16);
          
          globalArrowPositions.push({
            x,
            y: arrowY,
            type: 'LONG',
            entryPrice: data.entryPrice,
            exitPrice: data.exitPrice,
            profit: data.profit
          });
        } else if (data.text === 'SHORT') {
          const arrowY = signalY - 25; // Giảm từ 50 xuống 25
          ctx.fillStyle = signalRed;
          
          // Vẽ mũi tên
          ctx.beginPath();
          ctx.moveTo(x, arrowY + 10);
          ctx.lineTo(x - 5, arrowY);
          ctx.lineTo(x + 5, arrowY);
          ctx.closePath();
          ctx.fill();
          
          // Vẽ text với khoảng cách xa hơn từ mũi tên
          ctx.font = '12px Arial';
          ctx.fillText('SHORT', x, arrowY - 15);
          
          globalArrowPositions.push({
            x,
            y: arrowY,
            type: 'SHORT',
            entryPrice: data.entryPrice,
            exitPrice: data.exitPrice,
            profit: data.profit
          });
        }
      }
    }

    return false;
  }
});

// Phần code còn lại giữ nguyên
registerStyles('custom_style', {
  candle: {
    bar: {
      upColor: green,
      downColor: red,
      upBorderColor: black,
      downBorderColor: black,
      upWickColor: black,
      downWickColor: black
    },
    priceMark: {
      last: {
        upColor: green,
        downColor: red
      }
    }
  },
  indicator: {
    bars: [{
      upColor: green,
      downColor: red,
      noChangeColor: '#888888'
    }]
  }
});

const KlineChart: React.FC<KlineChartProps> = ({ data, trades, onClose }) => {
  const [selectedTimeframe, setSelectedTimeframe] = useState('15m');
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [drawingTool, setDrawingTool] = useState<string | null>(null);
  const chartRef = useRef<Chart | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const chartElementRef = useRef<HTMLDivElement>(null);
  const chartId = useRef(`chart-${Math.random().toString(36).substr(2, 9)}`);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!tooltipRef.current) {
      const tooltip = document.createElement('div');
      tooltip.id = 'chart-tooltip';
      tooltip.style.position = 'absolute';
      tooltip.style.display = 'none';
      tooltip.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      tooltip.style.color = 'white';
      tooltip.style.padding = '8px';
      tooltip.style.borderRadius = '4px';
      tooltip.style.fontSize = '12px';
      tooltip.style.zIndex = '1000';
      tooltip.style.pointerEvents = 'none';
      document.body.appendChild(tooltip);
      tooltipRef.current = tooltip;
    }

    return () => {
      if (tooltipRef.current) {
        document.body.removeChild(tooltipRef.current);
        tooltipRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!tooltipRef.current || !chartElementRef.current) return;

      const rect = chartElementRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      let hoveredArrow: ArrowPosition | null = null;
      for (const arrow of globalArrowPositions) {
        const distance = Math.sqrt(Math.pow(x - arrow.x, 2) + Math.pow(y - arrow.y, 2));
        if (distance < 15) {
          hoveredArrow = arrow;
          break;
        }
      }

      if (hoveredArrow) {
        tooltipRef.current.style.display = 'block';
        tooltipRef.current.style.left = (e.clientX + 10) + 'px';
        tooltipRef.current.style.top = (e.clientY + 10) + 'px';
        tooltipRef.current.innerHTML = `
          <div style="margin-bottom: 4px"><strong>${hoveredArrow.type}</strong></div>
          <div>Giá vào: ${hoveredArrow.entryPrice}</div>
          <div>Giá thoát: ${hoveredArrow.exitPrice}</div>
          <div>Lợi nhuận: ${hoveredArrow.profit}%</div>
        `;
      } else {
        tooltipRef.current.style.display = 'none';
      }
    };

    const handleMouseLeave = () => {
      if (tooltipRef.current) {
        tooltipRef.current.style.display = 'none';
      }
    };

    const chartElement = chartElementRef.current;
    if (chartElement) {
      chartElement.addEventListener('mousemove', handleMouseMove);
      chartElement.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (chartElement) {
        chartElement.removeEventListener('mousemove', handleMouseMove);
        chartElement.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current || !chartElementRef.current || !data) return;

    const currentChartId = chartId.current;

    if (!chartRef.current) {
      const chart = init(currentChartId, {
        styles: 'custom_style',
        timezone: 'Asia/Ho_Chi_Minh'
      });

      if (chart) {
        chartRef.current = chart;
        
        // Thêm volume indicator
        chart.createIndicator('VOL', false, { height: 80 });
        
        // Thêm signal indicator vào main pane
        chart.createIndicator('SIGNAL', true, { id: 'candle_pane' });
        
        chart.setPriceVolumePrecision(2, 0);
      }
    }

    if (chartRef.current && data.length > 0) {
      chartRef.current.applyNewData(data);

      if (trades && trades.length > 0) {
        trades.forEach(trade => {
          const timestamp = new Date(trade.time).getTime();
          chartRef.current?.createOverlay({
            name: 'simpleAnnotation',
            points: [{ timestamp, value: trade.price }],
            styles: {
              symbol: {
                type: trade.type === 'BUY' ? 'triangle' : 'reverseTriangle',
                size: 8,
                color: trade.type === 'BUY' ? green : red,
                activeSize: 10
              }
            }
          });
        });
      }
    }

    return () => {
      if (chartRef.current) {
        dispose(currentChartId);
        chartRef.current = null;
      }
    };
  }, [data, trades]);

  useEffect(() => {
    if (!containerRef.current || !chartElementRef.current) return;

    const resizeChart = () => {
      if (!containerRef.current || !chartElementRef.current || !chartRef.current) return;

      const { width, height } = containerRef.current.getBoundingClientRect();
      const toolbarHeight = 48;
      const bottomPadding = 40;
      const leftMenuWidth = 40;
      const chartHeight = height - toolbarHeight - bottomPadding;
      
      chartElementRef.current.style.width = `${width - leftMenuWidth}px`;
      chartElementRef.current.style.height = `${chartHeight}px`;
      chartElementRef.current.style.marginBottom = `${bottomPadding}px`;
      chartElementRef.current.style.marginLeft = `${leftMenuWidth}px`;
      
      requestAnimationFrame(() => {
        chartRef.current?.resize();
      });
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(resizeChart);
    });
    
    resizeObserver.observe(containerRef.current);
    resizeChart();

    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
      setTimeout(resizeChart, 100);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      resizeObserver.disconnect();
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const timeframes = ['1m', '5m', '15m', '1H', '2H', '4H', 'D', 'W', 'M', 'Y'];

  const toggleFullscreen = async () => {
    if (!containerRef.current) return;

    try {
      if (!isFullscreen) {
        await containerRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error('Fullscreen error:', error);
    }
  };

  const handleScreenshot = () => {
    if (!chartRef.current) return;
    
    try {
      const canvas = chartRef.current.getConvertPictureUrl();
      const link = document.createElement('a');
      link.download = 'chart-screenshot.png';
      link.href = canvas;
      link.click();
    } catch (error) {
      console.error('Screenshot error:', error);
    }
  };

  const handleIndicatorClick = () => {
    // TODO: Implement indicator selection modal for future use
  };

  const handleDrawingToolClick = (tool: string) => {
    if (!chartRef.current) return;

    if (drawingTool === tool) {
      setDrawingTool(null);
      chartRef.current.removeOverlay();
    } else {
      setDrawingTool(tool);
      chartRef.current.createOverlay({
        name: tool,
        points: [],
        styles: {
          line: {
            color: '#2196F3',
            size: 2
          }
        }
      });
    }
  };

  const clearAllDrawings = () => {
    if (!chartRef.current) return;
    chartRef.current.removeOverlay();
    setDrawingTool(null);
  };

  return (
    <div 
      ref={containerRef}
      style={{ 
        width: '100%', 
        height: '100%',
        display: 'flex', 
        flexDirection: 'column', 
        background: '#FFFFFF',
        margin: 0,
        padding: 0,
        position: 'relative'
      }}
    >
      <div style={{ 
        height: '48px',
        minHeight: '48px',
        borderBottom: '1px solid #EEEEEE', 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#FFFFFF',
        padding: '0 16px',
        zIndex: 1
      }}>
        <Space>
          {timeframes.map(tf => (
            <Button 
              key={tf}
              type={selectedTimeframe === tf ? 'primary' : 'default'}
              onClick={() => setSelectedTimeframe(tf)}
              size="small"
              style={{ 
                background: selectedTimeframe === tf ? '#2196F3' : '#FFFFFF',
                borderColor: selectedTimeframe === tf ? '#2196F3' : '#DDDDDD',
                color: selectedTimeframe === tf ? 'white' : '#666666'
              }}
            >
              {tf}
            </Button>
          ))}
        </Space>
        <Space>
          <Tooltip title="Indicator">
            <Button 
              icon={<LineChartOutlined />} 
              size="small" 
              onClick={handleIndicatorClick}
              style={{ borderColor: '#DDDDDD', color: '#666666' }} 
            />
          </Tooltip>
          <Tooltip title="Timezone">
            <Button icon={<FieldTimeOutlined />} size="small" style={{ borderColor: '#DDDDDD', color: '#666666' }} />
          </Tooltip>
          <Tooltip title="Screenshot">
            <Button icon={<CameraOutlined />} size="small" onClick={handleScreenshot} style={{ borderColor: '#DDDDDD', color: '#666666' }} />
          </Tooltip>
          <Tooltip title="Fullscreen">
            <Button icon={<FullscreenOutlined />} size="small" onClick={toggleFullscreen} style={{ borderColor: '#DDDDDD', color: '#666666' }} />
          </Tooltip>
          {onClose && (
            <Tooltip title="Close">
              <Button 
                icon={<CloseOutlined />} 
                size="small" 
                onClick={onClose}
                style={{ borderColor: '#DDDDDD', color: '#666666' }} 
              />
            </Tooltip>
          )}
        </Space>
      </div>

      <div style={{
        position: 'absolute',
        left: 0,
        top: '48px',
        bottom: '40px',
        width: '40px',
        background: '#FFFFFF',
        borderRight: '1px solid #EEEEEE',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '8px 0',
        zIndex: 2
      }}>
        <Tooltip title="Trendline lên" placement="right">
          <Button
            icon={<ArrowUpOutlined />}
            size="small"
            type={drawingTool === 'trendline_up' ? 'primary' : 'default'}
            onClick={() => handleDrawingToolClick('trendline_up')}
            style={{ 
              marginBottom: '8px',
              background: drawingTool === 'trendline_up' ? '#2196F3' : '#FFFFFF',
              borderColor: drawingTool === 'trendline_up' ? '#2196F3' : '#DDDDDD',
              color: drawingTool === 'trendline_up' ? 'white' : '#666666'
            }}
          />
        </Tooltip>
        <Tooltip title="Trendline xuống" placement="right">
          <Button
            icon={<ArrowDownOutlined />}
            size="small"
            type={drawingTool === 'trendline_down' ? 'primary' : 'default'}
            onClick={() => handleDrawingToolClick('trendline_down')}
            style={{ 
              marginBottom: '8px',
              background: drawingTool === 'trendline_down' ? '#2196F3' : '#FFFFFF',
              borderColor: drawingTool === 'trendline_down' ? '#2196F3' : '#DDDDDD',
              color: drawingTool === 'trendline_down' ? 'white' : '#666666'
            }}
          />
        </Tooltip>
        <Tooltip title="Xóa tất cả" placement="right">
          <Button
            icon={<DeleteOutlined />}
            size="small"
            onClick={clearAllDrawings}
            style={{ borderColor: '#DDDDDD', color: '#666666' }}
          />
        </Tooltip>
      </div>

      <div 
        ref={chartElementRef}
        id={chartId.current}
        style={{ 
          flex: 1,
          width: 'calc(100% - 40px)',
          marginLeft: '40px',
          position: 'relative'
        }} 
      />
    </div>
  );
};

export default KlineChart;
