import React from 'react';
import { Row, Col, Card, Typography, Input, Button, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Option } = Select;

interface StrategyHeaderProps {
  strategyName: string;
  isEditingName: boolean;
  selectedSymbol: string;
  selectedTimeframe: string;
  symbols: string[];
  timeframes: string[];
  onStrategyNameChange: (value: string) => void;
  onSymbolChange: (value: string) => void;
  onTimeframeChange: (value: string) => void;
  onEditNameStart: () => void;
  onEditNameEnd: () => void;
  handleUpdateStrategy: (updates: { name?: string; timeframe?: string; }) => Promise<void>;
}

const StrategyHeader: React.FC<StrategyHeaderProps> = ({
  strategyName,
  isEditingName,
  selectedSymbol,
  selectedTimeframe,
  symbols,
  timeframes,
  onStrategyNameChange,
  onSymbolChange,
  onTimeframeChange,
  onEditNameStart,
  onEditNameEnd,
  handleUpdateStrategy,
}) => {
  const handleNameChange = (value: string) => {
    onStrategyNameChange(value);
  };

  const handleNameEnd = () => {
    handleUpdateStrategy({ name: strategyName });
    onEditNameEnd();
  };

  const handleTimeframeChange = (value: string) => {
    onTimeframeChange(value);
    handleUpdateStrategy({ timeframe: value });
  };

  return (
    <Row gutter={[8, 8]}>
      <Col xs={24}>
        <Card
          size="small"
          style={{
            borderRadius: '4px',
            boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
          }}
          bodyStyle={{
            padding: '8px',
          }}
        >
          {isEditingName ? (
            <Input
              value={strategyName}
              onChange={(e) => handleNameChange(e.target.value)}
              onPressEnter={handleNameEnd}
              onBlur={handleNameEnd}
              autoFocus
              size="small"
              style={{ fontSize: '12px', width: '100%', padding: '4px 8px' }}
            />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 4px' }}>
              <Title level={5} style={{ margin: 0, fontSize: '12px' }}>{strategyName}</Title>
              <Button
                type="text"
                icon={<EditOutlined />}
                size="small"
                style={{ padding: 0, height: 'auto' }}
                onClick={onEditNameStart}
              />
            </div>
          )}
        </Card>
      </Col>

      <Col xs={12}>
        <Card
          size="small"
          style={{
            borderRadius: '4px',
            boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
          }}
          bodyStyle={{
            padding: '8px',
          }}
        >
          <div style={{ padding: '0 4px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Text style={{ fontSize: '11px' }}>Symbol</Typography.Text>
            </div>
            <Select
              value={selectedSymbol}
              onChange={onSymbolChange}
              style={{ width: '100%' }}
              size="small"
              dropdownMatchSelectWidth={false}
            >
              {symbols.map(symbol => (
                <Option key={symbol} value={symbol}>{symbol}</Option>
              ))}
            </Select>
          </div>
        </Card>
      </Col>

      <Col xs={12}>
        <Card
          size="small"
          style={{
            borderRadius: '4px',
            boxShadow: '0 1px 4px rgba(0,0,0,0.05)',
          }}
          bodyStyle={{
            padding: '8px',
          }}
        >
          <div style={{ padding: '0 4px' }}>
            <div style={{ marginBottom: '4px' }}>
              <Typography.Text style={{ fontSize: '11px' }}>Timeframe</Typography.Text>
            </div>
            <Select
              value={selectedTimeframe}
              onChange={handleTimeframeChange}
              style={{ width: '100%' }}
              size="small"
              dropdownMatchSelectWidth={false}
            >
              {timeframes.map(timeframe => (
                <Option key={timeframe} value={timeframe}>{timeframe}</Option>
              ))}
            </Select>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default StrategyHeader;
