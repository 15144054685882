import React from 'react';
import { Card, Row, Col, Typography, Tag, Divider, Empty } from 'antd';
import { PercentageOutlined, LineChartOutlined } from '@ant-design/icons';

const { Text } = Typography;

interface ExitConditionsProps {
  takeProfit: string;
  stopLoss: string;
  indicators: string[];
}

const ExitConditions: React.FC<ExitConditionsProps> = ({ takeProfit, stopLoss, indicators }) => {
  const hasConditions = takeProfit || stopLoss || indicators.length > 0;

  return (
    <Card
      size="small"
      className="strategy-card"
      title={
        <Typography.Title level={5} style={{ margin: 0, fontSize: '11px' }}>
          Điều kiện thoát lệnh
        </Typography.Title>
      }
      style={{ borderRadius: '4px' }}
      bodyStyle={{ 
        padding: '8px',
        minHeight: hasConditions ? 'auto' : '120px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: hasConditions ? 'flex-start' : 'center'
      }}
    >
      {hasConditions ? (
        <>
          <Row gutter={[8, 8]} style={{ margin: '0 4px' }}>
            <Col span={12}>
              <Card 
                size="small" 
                style={{ 
                  borderRadius: '4px', 
                  background: '#f6ffed', 
                  border: '1px solid #b7eb8f'
                }}
                bodyStyle={{ 
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                }}
              >
                <PercentageOutlined style={{ fontSize: '11px', color: '#52c41a' }} />
                <div>
                  <Text style={{ fontSize: '10px', color: '#52c41a', display: 'block', lineHeight: '14px' }}>
                    Take Profit
                  </Text>
                  <Text strong style={{ fontSize: '11px', color: '#52c41a', display: 'block', lineHeight: '16px' }}>
                    {takeProfit || '0%'}
                  </Text>
                </div>
              </Card>
            </Col>
            <Col span={12}>
              <Card 
                size="small" 
                style={{ 
                  borderRadius: '4px', 
                  background: '#fff1f0', 
                  border: '1px solid #ffa39e'
                }}
                bodyStyle={{ 
                  padding: '4px 8px',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px'
                }}
              >
                <PercentageOutlined style={{ fontSize: '11px', color: '#ff4d4f' }} />
                <div>
                  <Text style={{ fontSize: '10px', color: '#ff4d4f', display: 'block', lineHeight: '14px' }}>
                    Stop Loss
                  </Text>
                  <Text strong style={{ fontSize: '11px', color: '#ff4d4f', display: 'block', lineHeight: '16px' }}>
                    {stopLoss || '0%'}
                  </Text>
                </div>
              </Card>
            </Col>
          </Row>

          {indicators.length > 0 && (
            <>
              <Divider style={{ margin: '8px 0', fontSize: '10px' }}>Chỉ báo sử dụng</Divider>

              <div style={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '4px', 
                padding: '0 4px',
                justifyContent: 'flex-start'
              }}>
                {indicators.map((indicator, index) => (
                  <Tag 
                    key={index} 
                    color="blue"
                    style={{ 
                      padding: '1px 4px',
                      borderRadius: '4px',
                      fontSize: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '2px',
                      margin: '1px'
                    }}
                  >
                    <LineChartOutlined style={{ fontSize: '10px' }} />
                    {indicator}
                  </Tag>
                ))}
              </div>
            </>
          )}
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          imageStyle={{ height: 32 }}
          description={
            <Text style={{ fontSize: '11px', color: '#999' }}>
              Chưa có điều kiện
            </Text>
          }
        />
      )}
    </Card>
  );
};

export default ExitConditions;
