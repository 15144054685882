import React from 'react';
import { Modal, Form, Input, Select, Button, Typography } from 'antd';
import { TRADING_CONFIG } from '../../../config/trading.config';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

interface DeployModalProps {
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  form: any;
  isBotCreated: boolean;
  strategyId?: string;
  strategyName?: string;
}

const DeployModal: React.FC<DeployModalProps> = ({
  isVisible,
  onClose,
  onSubmit,
  form,
  isBotCreated,
  strategyId,
  strategyName
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      title="Triển khai chiến lược"
      open={isVisible}
      onCancel={onClose}
      footer={isBotCreated ? null : [
        <Button key="create" type="primary" onClick={form.submit}>
          Tạo Bot
        </Button>,
        <Button key="cancel" onClick={onClose}>
          Đóng
        </Button>,
      ]}
      width={520}
    >
      {isBotCreated ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Text strong>Bot đã được tạo thành công!</Text>
          <div style={{ marginTop: '20px' }}>
            <Button type="primary" onClick={() => navigate('/bot')}>
              Đi đến danh sách bot
            </Button>
          </div>
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          initialValues={{
            strategy: strategyId,
          }}
        >
          <Form.Item
            name="name"
            label="Tên Bot"
            rules={[{ required: true, message: 'Vui lòng nhập tên bot' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="strategy"
            label="Chiến lược"
            rules={[{ required: true, message: 'Vui lòng chọn chiến lược' }]}
          >
            <Select>
              <Select.Option value={strategyId}>
                {strategyName}
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="symbol"
            label="Cặp tiền"
            rules={[{ required: true, message: 'Vui lòng chọn cặp tiền' }]}
          >
            <Select>
              {TRADING_CONFIG.SYMBOLS.map(symbol => (
                <Select.Option key={symbol.value} value={symbol.value}>
                  {symbol.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default DeployModal;
