export interface Message {
  id: number;
  user: number;
  username: string;
  strategy: number;
  strategy_name: string;
  content: string;
  message_type: 'user' | 'assistant';
  created_at: string;
  strategy_update?: UIStrategy;
  entryConditions?: {
    buy: string[];
    sell: string[];
  };
  exitConditions?: {
    takeProfit: string;
    stopLoss: string;
  };
  indicators?: string[];
}

export interface ChatResponse {
  status: 'success' | 'thinking';
  type?: 'created' | 'completed';
  message?: string;
  user_message: Message;
  ai_message?: Message;
  function_call?: {
    name: string;
    arguments: Record<string, any>;
  };
  // Thêm các trường mới từ MQTT message
  error?: string;
  success?: boolean;
  output?: string;
  strategy_name?: string;
  execution_time?: number;
  // Thêm các trường mới cho type completed
  strategy_summary?: string;
  entryConditions?: {
    buy: string[];
    sell: string[];
  };
  exitConditions?: {
    takeProfit: string;
    stopLoss: string;
  };
  indicators?: string[];
}

export interface UIStrategy {
  entryConditions: {
    buy: string[];
    sell: string[];
  };
  exitConditions: {
    takeProfit: string;
    stopLoss: string;
  };
  indicators: string[];
}

export interface BacktestResult {
  profitLoss: number;
  winRate: number;
  totalTrades: number;
  averageProfit: number;
  averageLoss: number;
  maxDrawdown: number;
  duration: string;
  trades: Trade[];
  chartData?: any[];
}

export interface Trade {
  date: string;
  type: 'buy' | 'sell';
  profit: number;
}

export interface QuickReply {
  text: string;
  value: string;
  icon?: React.ReactNode;
}

// Hàm chuyển đổi từ API Strategy sang UI Strategy
export const mapAPIToUIStrategy = (apiStrategy: any): UIStrategy => {
  const conditions = apiStrategy.conditions || {};
  return {
    entryConditions: {
      buy: conditions.entryConditions?.buy || [],
      sell: conditions.entryConditions?.sell || [],
    },
    exitConditions: {
      takeProfit: conditions.exitConditions?.takeProfit || '',
      stopLoss: conditions.exitConditions?.stopLoss || '',
    },
    indicators: conditions.indicators || [],
  };
};
