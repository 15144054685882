import mqtt, { MqttClient, IClientOptions } from 'mqtt';

type ConnectionCallback = (isConnected: boolean) => void;

class MQTTService {
  private client: MqttClient | null = null;
  private static instance: MQTTService;
  private subscribers: Map<string, Function[]> = new Map();
  private isConnecting: boolean = false;
  private connectionPromise: Promise<void> | null = null;
  private instanceId: string;
  private _isConnected: boolean = false;
  private connectionCallbacks: Set<ConnectionCallback> = new Set();

  private constructor() {
    this.instanceId = Math.random().toString(16).substring(2, 8);
    console.log(`[MQTT ${this.instanceId}] Service instance created`);
    
    // Kết nối ngay khi khởi tạo service
    this.connect().catch(error => {
      console.error(`[MQTT ${this.instanceId}] Initial connection failed:`, error);
    });
  }

  public static getInstance(): MQTTService {
    if (!MQTTService.instance) {
      console.log('[MQTT] Creating new service instance');
      MQTTService.instance = new MQTTService();
    }
    return MQTTService.instance;
  }

  public isConnected(): boolean {
    return this._isConnected;
  }

  public onConnectionChange(callback: ConnectionCallback) {
    console.log(`[MQTT ${this.instanceId}] Adding connection callback, current status:`, this._isConnected);
    this.connectionCallbacks.add(callback);
    // Gọi callback ngay lập tức với trạng thái hiện tại
    callback(this._isConnected);
    
    // Return cleanup function
    return () => {
      console.log(`[MQTT ${this.instanceId}] Removing connection callback`);
      this.connectionCallbacks.delete(callback);
    };
  }

  private notifyConnectionChange(isConnected: boolean) {
    console.log(`[MQTT ${this.instanceId}] Checking connection change from ${this._isConnected} to ${isConnected}`);
    if (this._isConnected !== isConnected) {
      console.log(`[MQTT ${this.instanceId}] Connection status changed to:`, isConnected);
      this._isConnected = isConnected;
      this.connectionCallbacks.forEach(callback => {
        console.log(`[MQTT ${this.instanceId}] Notifying callback of new status:`, isConnected);
        callback(isConnected);
      });
    }
  }

  public isSubscribed(topic: string): boolean {
    return this.subscribers.has(topic) && this.subscribers.get(topic)!.length > 0;
  }

  private async ensureConnection(): Promise<void> {
    console.log(`[MQTT ${this.instanceId}] Ensuring connection...`);
    
    // Nếu đã có client và đang connected, không cần kết nối lại
    if (this._isConnected) {
      console.log(`[MQTT ${this.instanceId}] Already connected`);
      return;
    }

    // Nếu đang có một connection promise, trả về promise đó
    if (this.connectionPromise) {
      console.log(`[MQTT ${this.instanceId}] Connection already in progress, waiting...`);
      return this.connectionPromise;
    }

    // Tạo connection promise mới
    console.log(`[MQTT ${this.instanceId}] Starting new connection...`);
    this.connectionPromise = this.connect();

    try {
      await this.connectionPromise;
    } finally {
      this.connectionPromise = null;
    }
  }

  private connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      const host = 'i6a9edcc.ala.us-east-1.emqxsl.com';
      const port = '8084';
      const path = '/mqtt';
      const clientId = `mqttx_${Math.random().toString(16).substring(2, 10)}`;
      
      const connectUrl = `wss://${host}:${port}${path}`;
      console.log(`[MQTT ${this.instanceId}] Connecting to ${connectUrl} with clientId ${clientId}`);
      
      const options: IClientOptions = {
        clientId,
        username: 'sonnh',
        password: 'p9pv7YpaJ7yTJjf',
        clean: true,
        rejectUnauthorized: false,
        protocolVersion: 5,
        keepalive: 60,
        connectTimeout: 30000,
        reconnectPeriod: 5000
      };

      if (this.client) {
        console.log(`[MQTT ${this.instanceId}] Ending existing client`);
        this.client.end(true);
        this.notifyConnectionChange(false);
      }

      this.client = mqtt.connect(connectUrl, options);

      const connectTimeout = setTimeout(() => {
        console.log(`[MQTT ${this.instanceId}] Connection timeout`);
        this.notifyConnectionChange(false);
        reject(new Error('MQTT connection timeout'));
      }, options.connectTimeout);

      this.client.on('connect', () => {
        clearTimeout(connectTimeout);
        console.log(`[MQTT ${this.instanceId}] Connected to broker`);
        this.notifyConnectionChange(true);

        // Resubscribe to all topics
        if (this.subscribers.size > 0) {
          console.log(`[MQTT ${this.instanceId}] Resubscribing to ${this.subscribers.size} topics`);
          this.subscribers.forEach((_, topic) => {
            this.client?.subscribe(topic, { qos: 1 });
          });
        }

        resolve();
      });

      this.client.on('message', (topic: string, message: Buffer) => {
        try {
          const data = JSON.parse(message.toString());
          console.log(`[MQTT ${this.instanceId}] Message received on topic ${topic}:`, data);
          const subscribers = this.subscribers.get(topic) || [];
          subscribers.forEach(callback => callback(data));
        } catch (error) {
          console.error(`[MQTT ${this.instanceId}] Error parsing message:`, error);
        }
      });

      this.client.on('error', (error: Error) => {
        console.error(`[MQTT ${this.instanceId}] Error:`, error);
        if (!this._isConnected) {
          reject(error);
        }
      });

      this.client.on('close', () => {
        console.log(`[MQTT ${this.instanceId}] Connection closed`);
        this.notifyConnectionChange(false);
      });

      this.client.on('offline', () => {
        console.log(`[MQTT ${this.instanceId}] Client offline`);
        this.notifyConnectionChange(false);
      });

      this.client.on('reconnect', () => {
        console.log(`[MQTT ${this.instanceId}] Client reconnecting...`);
      });

      this.client.on('disconnect', () => {
        console.log(`[MQTT ${this.instanceId}] Client disconnected`);
        this.notifyConnectionChange(false);
      });

      this.client.on('end', () => {
        console.log(`[MQTT ${this.instanceId}] Client ended`);
        this.notifyConnectionChange(false);
      });
    });
  }

  public async subscribeToBacktest(jobId: number, callback: (data: any) => void) {
    await this.ensureConnection();

    const topic = `backtest/${jobId}/status`;
    console.log(`[MQTT ${this.instanceId}] Subscribing to backtest topic: ${topic}`);

    if (!this.subscribers.has(topic)) {
      this.subscribers.set(topic, []);
    }
    
    const subscribers = this.subscribers.get(topic)!;
    if (!subscribers.includes(callback)) {
      subscribers.push(callback);
    }

    if (this._isConnected) {
      this.client?.subscribe(topic, { qos: 1 }, (err) => {
        if (err) {
          console.error(`[MQTT ${this.instanceId}] Error subscribing to topic:`, err);
          return;
        }
        console.log(`[MQTT ${this.instanceId}] Subscribed to topic: ${topic}`);
      });
    }
  }

  public async subscribeToStrategyChat(username: string, strategyId: number, callback: (data: any) => void) {
    await this.ensureConnection();

    const topic = this.getStrategyChatTopic(username, strategyId);
    console.log(`[MQTT ${this.instanceId}] Subscribing to strategy chat topic: ${topic}`);

    if (!this.subscribers.has(topic)) {
      this.subscribers.set(topic, []);
    }
    
    const subscribers = this.subscribers.get(topic)!;
    if (!subscribers.includes(callback)) {
      subscribers.push(callback);
    }

    if (this._isConnected) {
      this.client?.subscribe(topic, { qos: 1 }, (err) => {
        if (err) {
          console.error(`[MQTT ${this.instanceId}] Error subscribing to strategy chat topic:`, err);
          return;
        }
        console.log(`[MQTT ${this.instanceId}] Subscribed to strategy chat topic: ${topic}`);
      });
    }
  }

  public async subscribeToNotifications(username: string, callback: (data: any) => void) {
    await this.ensureConnection();

    // Subscribe to user-specific notifications
    const userTopic = `${username}/notify`;
    console.log(`[MQTT ${this.instanceId}] Subscribing to user notifications: ${userTopic}`);

    if (!this.subscribers.has(userTopic)) {
      this.subscribers.set(userTopic, []);
    }
    
    const userSubscribers = this.subscribers.get(userTopic)!;
    if (!userSubscribers.includes(callback)) {
      userSubscribers.push(callback);
    }

    if (this._isConnected) {
      this.client?.subscribe(userTopic, { qos: 1 }, (err) => {
        if (err) {
          console.error(`[MQTT ${this.instanceId}] Error subscribing to user notifications:`, err);
          return;
        }
        console.log(`[MQTT ${this.instanceId}] Subscribed to user notifications: ${userTopic}`);
      });
    }

    // Subscribe to system-wide notifications
    const systemTopic = 'allsystem/notify';
    console.log(`[MQTT ${this.instanceId}] Subscribing to system notifications: ${systemTopic}`);

    if (!this.subscribers.has(systemTopic)) {
      this.subscribers.set(systemTopic, []);
    }
    
    const systemSubscribers = this.subscribers.get(systemTopic)!;
    if (!systemSubscribers.includes(callback)) {
      systemSubscribers.push(callback);
    }

    if (this._isConnected) {
      this.client?.subscribe(systemTopic, { qos: 1 }, (err) => {
        if (err) {
          console.error(`[MQTT ${this.instanceId}] Error subscribing to system notifications:`, err);
          return;
        }
        console.log(`[MQTT ${this.instanceId}] Subscribed to system notifications: ${systemTopic}`);
      });
    }
  }

  public unsubscribeFromBacktest(jobId: number, callback: Function) {
    const topic = `backtest/${jobId}/status`;
    console.log(`[MQTT ${this.instanceId}] Unsubscribing from backtest topic: ${topic}`);
    this.unsubscribeFromTopic(topic, callback);
  }

  public unsubscribeFromStrategyChat(username: string, strategyId: number, callback: Function) {
    const topic = this.getStrategyChatTopic(username, strategyId);
    console.log(`[MQTT ${this.instanceId}] Unsubscribing from strategy chat topic: ${topic}`);
    this.unsubscribeFromTopic(topic, callback);
  }

  public unsubscribeFromNotifications(username: string, callback: Function) {
    // Unsubscribe from user-specific notifications
    const userTopic = `${username}/notify`;
    console.log(`[MQTT ${this.instanceId}] Unsubscribing from user notifications: ${userTopic}`);
    this.unsubscribeFromTopic(userTopic, callback);

    // Unsubscribe from system-wide notifications
    const systemTopic = 'allsystem/notify';
    console.log(`[MQTT ${this.instanceId}] Unsubscribing from system notifications: ${systemTopic}`);
    this.unsubscribeFromTopic(systemTopic, callback);
  }

  private unsubscribeFromTopic(topic: string, callback: Function) {
    if (!this.subscribers.has(topic)) return;

    const subscribers = this.subscribers.get(topic)!;
    const index = subscribers.indexOf(callback);
    if (index > -1) {
      subscribers.splice(index, 1);
      console.log(`[MQTT ${this.instanceId}] Removed callback for topic: ${topic}`);
    }

    if (subscribers.length === 0) {
      this.subscribers.delete(topic);
      if (this._isConnected) {
        this.client?.unsubscribe(topic, (err) => {
          if (err) {
            console.error(`[MQTT ${this.instanceId}] Error unsubscribing from topic:`, err);
          } else {
            console.log(`[MQTT ${this.instanceId}] Unsubscribed from topic: ${topic}`);
          }
        });
      }
    }
  }

  public getStrategyChatTopic(username: string, strategyId: number): string {
    return `${username}/strategychat/${strategyId}`;
  }

  public getUserNotifyTopic(username: string): string {
    return `${username}/notify`;
  }

  public getSystemNotifyTopic(): string {
    return 'allsystem/notify';
  }

  public disconnect() {
    if (this.client) {
      console.log(`[MQTT ${this.instanceId}] Disconnecting...`);
      this.client.end(true);
      this.client = null;
      this.notifyConnectionChange(false);
      this.subscribers.clear();
      console.log(`[MQTT ${this.instanceId}] Disconnected and cleaned up`);
    }
  }
}

export default MQTTService;
