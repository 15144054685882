import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { RobotOutlined, LineChartOutlined, ThunderboltOutlined } from '@ant-design/icons';
import '../styles/LandingPage.css';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="landing-page">
      <header className="landing-header">
        <nav>
          <div className="logo">Mew Trader</div>
          <div className="auth-buttons">
            <button onClick={() => navigate('/login')} className="login-btn">
              Đăng nhập
            </button>
            <button onClick={() => navigate('/register')} className="register-btn">
              Đăng ký
            </button>
          </div>
        </nav>
      </header>

      <main>
        <section className="hero-section">
          <h1>Tạo Chiến Lược Giao Dịch Nhanh Chóng Với AI</h1>
          <p>Chat với AI để xây dựng và tối ưu hóa chiến lược giao dịch của bạn</p>
          <div className="hero-buttons">
            <Button type="primary" size="large" onClick={() => navigate('/login')}>
              Bắt đầu ngay
            </Button>
            <Button size="large">Tìm hiểu thêm</Button>
          </div>
          <div className="hero-image">
            <img src="/demo.jpg" alt="AI Chat Interface" />
          </div>
        </section>

        <section className="features-section">
          <div className="feature-card">
            <RobotOutlined className="feature-icon" />
            <h3>Chat với AI Assistant</h3>
            <p>Tạo và tối ưu chiến lược giao dịch thông qua trò chuyện tự nhiên với AI. Không cần kiến thức lập trình, chỉ cần mô tả ý tưởng của bạn.</p>
            <div className="feature-demo">
              <div className="chat-demo">
                <div className="message user">Tôi muốn tạo chiến lược giao dịch dựa trên RSI và MACD</div>
                <div className="message bot">Tôi có thể giúp bạn tạo chiến lược đó. Hãy cho tôi biết các điều kiện cụ thể bạn muốn:</div>
              </div>
            </div>
          </div>

          <div className="feature-card">
            <LineChartOutlined className="feature-icon" />
            <h3>Công Cụ Phân Tích Chuyên Sâu</h3>
            <p>Hệ thống biểu đồ tiên tiến với đầy đủ các chỉ báo kỹ thuật và công cụ phân tích. Dễ dàng theo dõi và phân tích thị trường theo thời gian thực.</p>
            <div className="feature-demo">
              <div className="chart-demo">
                <img src="/demo.jpg" alt="Mew Chart Interface" />
              </div>
            </div>
          </div>

          <div className="feature-card">
            <ThunderboltOutlined className="feature-icon" />
            <h3>Tự Động Hóa Giao Dịch</h3>
            <p>Triển khai chiến lược của bạn thành bot giao dịch tự động. Theo dõi hiệu suất và tối ưu hóa theo thời gian thực.</p>
            <div className="feature-demo">
              <div className="bot-demo">
                <img src="/images/dashboard_chart.jpg" alt="Bot Dashboard" />
              </div>
            </div>
          </div>
        </section>

        <section className="workflow-section">
          <h2>Quy Trình Đơn Giản</h2>
          <div className="workflow-steps">
            <div className="step">
              <div className="step-number">1</div>
              <h4>Chat với AI</h4>
              <p>Mô tả chiến lược giao dịch của bạn bằng ngôn ngữ tự nhiên</p>
            </div>
            <div className="step">
              <div className="step-number">2</div>
              <h4>Kiểm Thử</h4>
              <p>Test chiến lược trên dữ liệu lịch sử với công cụ backtesting tích hợp</p>
            </div>
            <div className="step">
              <div className="step-number">3</div>
              <h4>Triển Khai</h4>
              <p>Tự động hóa chiến lược với bot giao dịch 24/7</p>
            </div>
          </div>
        </section>

        <section className="cta-section">
          <h2>Sẵn sàng tạo chiến lược giao dịch của riêng bạn?</h2>
          <Button type="primary" size="large" onClick={() => navigate('/register')}>
            Bắt đầu miễn phí
          </Button>
        </section>
      </main>

      <footer>
        <p>&copy; 2024 Mew Trader. Nền tảng tạo chiến lược giao dịch với AI.</p>
      </footer>
    </div>
  );
};

export default LandingPage;
