import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Card, Button, Table, Statistic, Tag, Space, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ArrowUpOutlined, ArrowDownOutlined, CloseCircleOutlined, StopOutlined } from '@ant-design/icons';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import KlineChart from '../strategy/KlineChart';
import { mockTradeHistory, mockBotPerformance, mockBotChartData, mockDailyProfits, mockProfitDistribution, mockOpenTrades } from '../../data/mockData';

const { Content } = Layout;

interface OpenTrade {
  id: number;
  type: string;
  symbol: string;
  entryPrice: number;
  currentPrice: number;
  quantity: number;
  unrealizedProfit: number;
  profitPercentage: number;
  timestamp: string;
  status: string;
  orderStatus: string;
}

interface HistoryTrade {
  id: number;
  type: string;
  symbol: string;
  entryPrice: number;
  exitPrice: number;
  quantity: number;
  profit: number;
  profitPercentage: number;
  timestamp: string;
  status: string;
}

const BotDetail: React.FC = () => {
  const [isTradeHistoryVisible, setTradeHistoryVisible] = useState(true);
  const [chartData, setChartData] = useState(mockBotChartData.data);
  const [trades, setTrades] = useState(mockBotChartData.trades);

  useEffect(() => {
    setChartData(mockBotChartData.data);
    setTrades(mockBotChartData.trades);
  }, []);

  const handleCloseTrade = (tradeId: number) => {
    console.log('Đóng lệnh:', tradeId);
  };

  const handleCancelOrder = (tradeId: number) => {
    console.log('Hủy lệnh:', tradeId);
  };

  const formatNumber = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  const dailyProfitOptions = {
    chart: {
      type: 'line',
      height: 300,
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue'
      }
    },
    title: {
      text: 'Profit theo ngày',
      style: { fontSize: '14px' }
    },
    xAxis: {
      type: 'datetime',
      title: { text: 'Ngày' },
      labels: { style: { fontSize: '12px' } }
    },
    yAxis: {
      title: { text: 'Profit (USDT)' },
      labels: { style: { fontSize: '12px' } }
    },
    series: [{
      name: 'Profit',
      data: mockDailyProfits.map(item => [item.timestamp, item.profit]),
      color: '#2ecc71',
      negativeColor: '#e74c3c'
    }],
    tooltip: {
      valuePrefix: '',
      valueSuffix: ' USDT',
      style: { fontSize: '12px' }
    },
    credits: { enabled: false }
  };

  const profitDistributionOptions = {
    chart: {
      type: 'column',
      height: 300,
      style: {
        fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue'
      }
    },
    title: {
      text: 'Phân phối lãi lỗ',
      style: { fontSize: '14px' }
    },
    xAxis: {
      categories: mockProfitDistribution.map(item => item.range),
      title: { text: 'Profit Range (USDT)' },
      labels: { style: { fontSize: '12px' } }
    },
    yAxis: {
      title: { text: 'Số lượng giao dịch' },
      labels: { style: { fontSize: '12px' } }
    },
    series: [{
      name: 'Số giao dịch',
      data: mockProfitDistribution.map(item => item.count),
      color: '#3498db'
    }],
    plotOptions: {
      column: { borderRadius: 5 }
    },
    credits: { enabled: false }
  };

  const openTradesColumns: ColumnsType<OpenTrade> = [
    {
      title: 'Thời gian',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 180,
      render: (text: string) => new Date(text).toLocaleString('vi-VN'),
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (text: string) => (
        <Tag color={text === 'BUY' ? '#52c41a' : '#f5222d'}>
          {text}
        </Tag>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 120,
    },
    {
      title: 'Giá vào',
      dataIndex: 'entryPrice',
      key: 'entryPrice',
      width: 120,
      render: (value: number) => formatNumber(value),
    },
    {
      title: 'Giá hiện tại',
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      width: 120,
      render: (value: number) => formatNumber(value),
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (value: number) => formatNumber(value),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      width: 120,
      render: (_: unknown, record: OpenTrade) => {
        let color = 'default';
        let text = record.status;
        
        if (record.status === 'FILLED' && record.orderStatus === 'ACTIVE') {
          color = 'green';
          text = 'Đang giao dịch';
        } else if (record.status === 'PENDING') {
          color = 'gold';
          text = 'Chờ khớp lệnh';
        }
        
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Lợi nhuận',
      key: 'profit',
      width: 180,
      render: (_: unknown, record: OpenTrade) => {
        if (record.status === 'PENDING') return '-';
        return (
          <span style={{ color: record.unrealizedProfit >= 0 ? '#52c41a' : '#f5222d' }}>
            {record.unrealizedProfit >= 0 ? '+' : ''}{formatNumber(record.unrealizedProfit)} USDT
            <br />
            ({record.unrealizedProfit >= 0 ? '+' : ''}{record.profitPercentage.toFixed(2)}%)
          </span>
        );
      },
    },
    {
      title: 'Hành động',
      key: 'action',
      fixed: 'right' as const,
      width: 100,
      render: (_: unknown, record: OpenTrade) => (
        <Space size="small">
          {record.status === 'FILLED' && record.orderStatus === 'ACTIVE' && (
            <Tooltip title="Đóng lệnh">
              <Button
                type="primary"
                danger
                size="small"
                icon={<CloseCircleOutlined />}
                onClick={() => handleCloseTrade(record.id)}
              >
                Đóng
              </Button>
            </Tooltip>
          )}
          {record.status === 'PENDING' && (
            <Tooltip title="Hủy lệnh">
              <Button
                danger
                size="small"
                icon={<StopOutlined />}
                onClick={() => handleCancelOrder(record.id)}
              >
                Hủy
              </Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const historyColumns: ColumnsType<HistoryTrade> = [
    {
      title: 'Thời gian',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 180,
      render: (text: string) => new Date(text).toLocaleString('vi-VN'),
    },
    {
      title: 'Loại',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (text: string) => (
        <Tag color={text === 'BUY' ? '#52c41a' : '#f5222d'}>
          {text}
        </Tag>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      width: 120,
    },
    {
      title: 'Giá vào',
      dataIndex: 'entryPrice',
      key: 'entryPrice',
      width: 120,
      render: (value: number) => formatNumber(value),
    },
    {
      title: 'Giá ra',
      dataIndex: 'exitPrice',
      key: 'exitPrice',
      width: 120,
      render: (value: number) => formatNumber(value),
    },
    {
      title: 'Lợi nhuận',
      dataIndex: 'profit',
      key: 'profit',
      width: 180,
      render: (profit: number) => (
        <span style={{ color: profit >= 0 ? '#52c41a' : '#f5222d' }}>
          {profit >= 0 ? '+' : ''}{formatNumber(profit)} USDT
          <br />
          ({profit >= 0 ? '+' : ''}{(profit / mockBotPerformance.totalProfit * 100).toFixed(2)}%)
        </span>
      ),
    },
  ];

  return (
    <Layout style={{ 
      height: '100vh',
      background: '#f0f2f5',
      overflow: 'hidden',
    }}>
      <Content 
        style={{ 
          height: '100%', 
          padding: '16px',
          overflow: 'auto'
        }}
      >
        <div style={{ maxWidth: 1600, margin: '0 auto' }}>
          <Row gutter={[16, 16]}>
            {/* Header với thống kê tổng quan */}
            <Col xs={24}>
              <Card bordered={false}>
                <Row gutter={[32, 16]}>
                  <Col xs={24} sm={12} lg={6}>
                    <Statistic
                      title="Tổng lợi nhuận"
                      value={mockBotPerformance.totalProfit}
                      precision={2}
                      prefix={mockBotPerformance.totalProfit >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                      suffix="USDT"
                      valueStyle={{ 
                        color: mockBotPerformance.totalProfit >= 0 ? '#3f8600' : '#cf1322',
                        fontSize: '24px'
                      }}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Statistic
                      title="Tỷ lệ thắng"
                      value={mockBotPerformance.winRate}
                      precision={2}
                      suffix="%"
                      valueStyle={{ fontSize: '24px' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Statistic
                      title="Tổng giao dịch"
                      value={mockBotPerformance.totalTrades}
                      valueStyle={{ fontSize: '24px' }}
                    />
                  </Col>
                  <Col xs={24} sm={12} lg={6}>
                    <Statistic
                      title="Profit Factor"
                      value={mockBotPerformance.profitFactor}
                      precision={2}
                      valueStyle={{ fontSize: '24px' }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* Biểu đồ và Thống kê hiệu suất */}
            <Col xs={24}>
              <Row gutter={[16, 16]}>
                {/* Biểu đồ giao dịch */}
                <Col xs={24} xl={17}>
                  <Card 
                    bordered={false}
                    bodyStyle={{ 
                      padding: 0,
                      height: '600px',
                      overflow: 'hidden'
                    }}
                    style={{
                      height: '600px',
                      overflow: 'hidden'
                    }}
                  >
                    {chartData && chartData.length > 0 && (
                      <KlineChart 
                        data={chartData}
                        trades={trades}
                      />
                    )}
                  </Card>
                </Col>

                {/* Thống kê hiệu suất chi tiết */}
                <Col xs={24} xl={7}>
                  <Card 
                    title="Thống kê hiệu suất" 
                    bordered={false}
                    style={{ height: '600px' }}
                  >
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                      <Statistic
                        title="Drawdown tối đa"
                        value={mockBotPerformance.maxDrawdown}
                        precision={2}
                        suffix="USDT"
                        valueStyle={{ color: '#cf1322', fontSize: '20px' }}
                      />
                      <Statistic
                        title="Lợi nhuận trung bình/giao dịch"
                        value={mockBotPerformance.averageProfit}
                        precision={2}
                        suffix="USDT"
                        valueStyle={{ fontSize: '20px' }}
                      />
                      <Statistic
                        title="Sharpe Ratio"
                        value={mockBotPerformance.sharpeRatio}
                        precision={2}
                        valueStyle={{ fontSize: '20px' }}
                      />
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* Giao dịch đang mở */}
            <Col xs={24}>
              <Card 
                title="Giao dịch đang mở & Lệnh chờ"
                bordered={false}
              >
                <Table
                  dataSource={mockOpenTrades as OpenTrade[]}
                  columns={openTradesColumns}
                  rowKey="id"
                  pagination={false}
                  scroll={{ x: 1200 }}
                  size="middle"
                />
              </Card>
            </Col>

            {/* Biểu đồ profit theo ngày */}
            <Col xs={24} lg={12}>
              <Card bordered={false}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={dailyProfitOptions}
                />
              </Card>
            </Col>

            {/* Biểu đồ phân phối lãi lỗ */}
            <Col xs={24} lg={12}>
              <Card bordered={false}>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={profitDistributionOptions}
                />
              </Card>
            </Col>

            {/* Lịch sử giao dịch */}
            <Col xs={24}>
              <Card
                title="Lịch sử giao dịch"
                bordered={false}
                extra={
                  <Button 
                    type="link"
                    onClick={() => setTradeHistoryVisible(!isTradeHistoryVisible)}
                  >
                    {isTradeHistoryVisible ? 'Thu gọn' : 'Mở rộng'}
                  </Button>
                }
              >
                {isTradeHistoryVisible && (
                  <Table
                    dataSource={mockTradeHistory as HistoryTrade[]}
                    columns={historyColumns}
                    rowKey="id"
                    pagination={{ 
                      pageSize: 10,
                      showSizeChanger: true,
                      showQuickJumper: true
                    }}
                    scroll={{ x: 1000 }}
                    size="middle"
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

export default BotDetail;
