import axios from 'axios';
import { API_CONFIG } from '../config/api.config';

const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...API_CONFIG.HEADERS,
      'Authorization': `Bearer ${token}`
    }
  };
};

const ChatService = {
  getChatHistory: async (strategyId: number) => {
    return axios.get(
      `${API_CONFIG.BASE_URL}/api/trading/chat/?strategy=${strategyId}`,
      getAuthHeader()
    );
  },

  sendMessage: async (strategyId: number, content: string) => {
    return axios.post(
      `${API_CONFIG.BASE_URL}/api/trading/chat/`,
      {
        strategy: strategyId,
        content,
      },
      getAuthHeader()
    );
  },

  resetChat: async (strategyId: number) => {
    return axios.post(
      `${API_CONFIG.BASE_URL}/api/trading/chat/reset/`,
      {
        strategy: strategyId
      },
      getAuthHeader()
    );
  },
};

export default ChatService;
