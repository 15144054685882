export const TRADING_CONFIG = {
    SYMBOLS: [
        { value: 'BTCUSDT', label: 'BTC/USDT' },
        { value: 'ETHUSDT', label: 'ETH/USDT' },
        { value: 'BNBUSDT', label: 'BNB/USDT' },
        { value: 'ADAUSDT', label: 'ADA/USDT' },
        { value: 'DOGEUSDT', label: 'DOGE/USDT' },
        { value: 'XRPUSDT', label: 'XRP/USDT' }
    ],

    STRATEGIES: [
        { 
            value: 'grid', 
            label: 'Grid Trading',
            description: 'Chiến lược giao dịch lưới, tự động mua khi giá xuống và bán khi giá lên trong một khoảng giá xác định',
            defaultParams: {
                upperLimit: 0,
                lowerLimit: 0,
                gridLines: 10,
                investment: 1000
            }
        },
        { 
            value: 'dca', 
            label: 'DCA (Dollar Cost Average)',
            description: 'Chiến lược trung bình giá, tự động mua với số tiền cố định theo chu kỳ',
            defaultParams: {
                interval: '1d',
                amount: 100,
                maxPositions: 10
            }
        },
        { 
            value: 'momentum', 
            label: 'Momentum Trading',
            description: 'Chiến lược giao dịch theo đà, sử dụng các chỉ báo động lượng để xác định điểm vào lệnh',
            defaultParams: {
                rsiPeriod: 14,
                rsiOverbought: 70,
                rsiOversold: 30,
                stopLoss: 5,
                takeProfit: 10
            }
        }
    ],

    INTERVALS: [
        { value: '1m', label: '1 phút' },
        { value: '5m', label: '5 phút' },
        { value: '15m', label: '15 phút' },
        { value: '30m', label: '30 phút' },
        { value: '1h', label: '1 giờ' },
        { value: '4h', label: '4 giờ' },
        { value: '1d', label: '1 ngày' },
        { value: '1w', label: '1 tuần' }
    ],

    DEFAULT_TRADE_AMOUNT: 100,
    MIN_TRADE_AMOUNT: 10,
    MAX_TRADE_AMOUNT: 10000,

    DEFAULT_GRID_LINES: 10,
    MIN_GRID_LINES: 3,
    MAX_GRID_LINES: 100,

    DEFAULT_STOP_LOSS: 5, // %
    DEFAULT_TAKE_PROFIT: 10, // %

    MAX_ACTIVE_BOTS: 10,
    MAX_ACTIVE_TRADES_PER_BOT: 100,

    PRICE_PRECISION: {
        'BTCUSDT': 2,
        'ETHUSDT': 2,
        'BNBUSDT': 2,
        'ADAUSDT': 4,
        'DOGEUSDT': 5,
        'XRPUSDT': 4
    },

    QUANTITY_PRECISION: {
        'BTCUSDT': 6,
        'ETHUSDT': 5,
        'BNBUSDT': 4,
        'ADAUSDT': 2,
        'DOGEUSDT': 0,
        'XRPUSDT': 1
    }
};
